import { gsap } from './gsap_loader'

export function Contact(el) {
  let sub_title = el.querySelector('.sub_title')
  let more_btn = el.querySelector('.more_btn')
  let cover = el.querySelector('.cover')
  let title = el.querySelector('.title')
  let title_cover = el.querySelector('.title_cover')
  let social_btn = el.querySelectorAll('.social_btn')

  let scroll_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top bottom',
      toggleActions: 'restart play play reverse',
    },
  })

  scroll_tl.fromTo(
    cover,
    {
      opacity: 0.2,
    },
    {
      opacity: 1,
      duration: 0.4,
    },
    0
  )

  scroll_tl.fromTo(
    sub_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.4,
      duration: 0.3,
    },
    0
  )

  scroll_tl.fromTo(
    more_btn,
    {
      x: '-30%',
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      delay: 0.4,
      duration: 0.3,
    },
    0
  )

  scroll_tl.fromTo(
    title_cover,
    {
      opacity: 0,
      scaleX: 0,
      transformOrigin: 'left',
    },
    {
      opacity: 1,
      scaleX: 1,
      transformOrigin: 'left',
      delay: 0.4,
      duration: 0.3,
    },
    0
  )

  scroll_tl.fromTo(
    title,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.7,
    },
    0
  )

  scroll_tl.fromTo(
    title_cover,
    {
      scaleX: 1,
      transformOrigin: 'right',
    },
    {
      scaleX: 0,
      transformOrigin: 'right',
      delay: 0.7,
      duration: 0.3,
    },
    0
  )
  scroll_tl.fromTo(
    social_btn,
    {
      opacity: 0,
      y: '30px',
    },
    {
      opacity: 1,
      y: '0px',
      stagger: 0.1,
      delay: 0.7,
      duration: 0.3,
    },
    0
  )

  //    stagger: 0.1,
}

export function Marquee(el) {
  let carousel = el.querySelector('.carousel')
  let cards = el.querySelectorAll('.card')
  let width = cards[0].clientWidth * (cards.length / 2)
  let carousel_tl = gsap.timeline({
    repeat: -1,
  })
  carousel_tl.fromTo(
    carousel,
    {
      x: '0px',
    },
    {
      x: `-${width}px`,
      duration: 30,
      ease: 'none',
    }
  )
  return carousel_tl
}
