import { gsap } from './gsap_loader'
var share_dialog_tl = null
var shop_cart_tl = null
var shop_cart_timeline_tl = null

export function OpenShareDialog(el) {
  let bg_cover = el.querySelector('.bg_cover')
  let main_box = el.querySelector('.main_box')
  let share_button = el.querySelectorAll('.share_button')
  share_dialog_tl != null ? share_dialog_tl.kill() : ''
  share_dialog_tl = gsap.timeline()
  share_dialog_tl.set(
    el,
    {
      x: '100%',
    },
    0
  )
  share_dialog_tl.fromTo(
    bg_cover,
    {
      opacity: 0,
    },
    {
      opacity: 0.5,
    },
    0
  )
  share_dialog_tl.fromTo(
    main_box,
    {
      opacity: 0,
      y: '30%',
    },
    {
      opacity: 1,
      y: '0%',
      delay: 0.4,
    },
    0
  )
  share_dialog_tl.fromTo(
    share_button,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.6,
      stagger: 0.3,
    },
    0
  )
}
export function CloseShareDialog() {
  share_dialog_tl.reverse()
}
export function OpenShopCartDialog(el) {
  let timeline = el.querySelector('.timeline')
  let content = el.querySelector('.content')
  shop_cart_tl != null ? shop_cart_tl.kill() : ''
  shop_cart_timeline_tl != null ? shop_cart_timeline_tl.kill() : ''
  shop_cart_tl = gsap.timeline()
  shop_cart_timeline_tl = gsap.timeline()
  shop_cart_tl.set(
    el,
    {
      x: '-50%',
      y: '100vh',
    },
    0
  )
  shop_cart_tl.fromTo(
    content,
    {
      opacity: 0,
      y: '-30px',
    },
    {
      opacity: 1,
      y: '0px',
    },
    0
  )
  shop_cart_timeline_tl.fromTo(
    timeline,
    {
      transformOrigin: 'left',
      scaleX: 0,
    },
    {
      transformOrigin: 'left',
      scaleX: 1,
      duration: 3,
    },
    0
  )
}
export function CloseShopCartDialog() {
  shop_cart_tl.reverse()
}
