import { gsap } from './gsap_loader'

var cover_tl = null
var cover_scroll_tl = null

export function Cover(el) {
  let title = el.querySelector('.title')
  let sub_title = el.querySelector('.sub_title')
  cover_tl != null ? cover_tl.kill() : ''
  cover_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top center',
      toggleActions: 'play play play play',
    },
  })

  cover_tl.fromTo(
    title,
    {
      opacity: 0,
      y: '-100%',
    },
    {
      opacity: 1,
      y: '0%',
    }
  )
  cover_tl.fromTo(
    sub_title,
    {
      opacity: 0,
      y: '100%',
    },
    {
      opacity: 1,
      y: '0%',
    }
  )
}
export function CoverScroll(el) {
  let title = el.querySelector('.title')
  let sub_title = el.querySelector('.sub_title')
  cover_scroll_tl != null ? cover_scroll_tl.kill() : ''
  cover_scroll_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      scrub: true,
      start: 'top top',
      end: 'bottom top',
    },
  })

  cover_scroll_tl.fromTo(
    title,
    {
      y: '0%',
    },
    {
      y: '100%',
    },
    0
  )
  cover_scroll_tl.fromTo(
    sub_title,
    {
      y: '0%',
    },
    {
      y: '-100%',
    },
    0
  )
}

export function AboutCarousel(carousel, active_index, timeline) {
  gsap.to(carousel, {
    x: `${active_index * -100}%`,
    ease: 'power4.inOut',
  })
  gsap.fromTo(
    timeline,
    {
      scaleY: 0,
      transformOrigin: 'top',
    },
    {
      scaleY: 1,
      transformOrigin: 'top',
      duration: 5,
    }
  )
}

export function AboutContent(el) {
  let title = el.querySelector('.title')
  let title_cover = el.querySelector('.title_cover')
  let sub_title = el.querySelector('.sub_title')
  let content = el.querySelector('.content')
  let more_btn = el.querySelector('.more_btn')

  let scroll_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top center',
      toggleActions: 'restart play play reverse',
    },
  })

  scroll_tl.fromTo(
    title_cover,
    {
      transformOrigin: 'left',
      scaleX: 0,
      opacity: 0,
    },
    {
      transformOrigin: 'left',
      scaleX: 1,
      opacity: 1,
      duration: 0.3,
    },
    0
  )
  scroll_tl.fromTo(
    title,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.3,
    },
    0
  )
  scroll_tl.fromTo(
    title_cover,
    {
      transformOrigin: 'right',
      scaleX: 1,
    },
    {
      transformOrigin: 'right',
      scaleX: 0,
      duration: 0.3,
      delay: 0.3,
    },
    0
  )
  scroll_tl.fromTo(
    sub_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      duration: 0.6,
    },
    0
  )
  scroll_tl.fromTo(
    content,
    {
      y: '30%',
      opacity: 0,
    },
    {
      y: '0%',
      opacity: 1,
      duration: 0.8,
    },
    0
  )
  scroll_tl.fromTo(
    more_btn,
    {
      x: '-30%',
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      duration: 0.8,
    },
    0
  )
}

export function MainProductCard(el, main_content) {
  let title = main_content.querySelector('.title')
  let sub_title = main_content.querySelector('.sub_title')
  let type_title = main_content.querySelector('.type_title')
  let option_title = main_content.querySelector('.option_title')
  let options = main_content.querySelectorAll('.option')
  let more_btn = main_content.querySelector('.more_btn')
  let card_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: '30% center',
      toggleActions: 'restart play play reverse',
    },
  })
  card_tl.fromTo(
    main_content,
    {
      opacity: 0,
      y: '30%',
    },
    {
      opacity: 1,
      y: '0%',
    },
    0
  )
  card_tl.fromTo(
    title,
    {
      opacity: 0,
      y: '10%',
    },
    {
      opacity: 1,
      y: '0%',
      duration: 0.3,
    },
    1
  )
  card_tl.fromTo(
    sub_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.3,
    },
    1
  )
  card_tl.fromTo(
    type_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.3,
    },
    1
  )
  card_tl.fromTo(
    option_title,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
      delay: 0.6,
    },
    1
  )
  card_tl.fromTo(
    options,
    {
      opacity: 0,
      y: '-30%',
    },
    {
      opacity: 1,
      y: '0%',
      stagger: 0.1,
      delay: 0.8,
    },
    1
  )
  card_tl.fromTo(
    more_btn,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
      delay: 0.8,
    },
    1
  )
  return card_tl
}

export function MbProductCard(el, main_content) {
  let scooter = el.querySelectorAll('.scooter')
  let title = main_content.querySelector('.title')
  let sub_title = main_content.querySelector('.sub_title')
  let type_title = main_content.querySelector('.type_title')
  let option_title = main_content.querySelector('.option_title')
  let options = main_content.querySelectorAll('.option')
  let more_btn = main_content.querySelector('.more_btn')
  let card_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: '30% center',
      toggleActions: 'restart play play reverse',
    },
  })
  card_tl.fromTo(
    scooter,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
    },
    0
  )
  card_tl.fromTo(
    main_content,
    {
      opacity: 0,
      y: '30%',
    },
    {
      opacity: 1,
      y: '0%',
    },
    0
  )
  card_tl.fromTo(
    title,
    {
      opacity: 0,
      y: '10%',
    },
    {
      opacity: 1,
      y: '0%',
      duration: 0.3,
    },
    1
  )
  card_tl.fromTo(
    sub_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.3,
    },
    1
  )
  card_tl.fromTo(
    type_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.3,
    },
    1
  )
  card_tl.fromTo(
    option_title,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
      delay: 0.6,
    },
    1
  )
  card_tl.fromTo(
    options,
    {
      opacity: 0,
      y: '-30%',
    },
    {
      opacity: 1,
      y: '0%',
      stagger: 0.1,
      delay: 0.8,
    },
    1
  )
  card_tl.fromTo(
    more_btn,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
      delay: 0.8,
    },
    1
  )
  return card_tl
}

export function OutProductCard(card_tl) {
  card_tl != null ? card_tl.reverse() : ''
}

export function MainProductCarousel(carousel, cards, active_index, time_line) {
  let width = cards[0].clientWidth + cards[0].clientWidth * 0.33
  let timeline_tl = gsap.timeline()
  let tl = gsap.timeline()
  tl.to(
    carousel,
    {
      x: `${active_index * width * -1}px`,
      ease: 'power4.inOut',
      duration: 0.6,
    },
    0
  )

  timeline_tl.fromTo(
    time_line,
    {
      transformOrigin: 'left',
      scaleX: 0.5,
    },
    {
      transformOrigin: 'left',
      scaleX: 1,
    },
    0
  )

  timeline_tl.fromTo(
    time_line,
    {
      transformOrigin: 'right',
      scaleX: 1,
    },
    {
      transformOrigin: 'right',
      scaleX: 0,
    },
    1
  )
  timeline_tl.fromTo(
    time_line,
    {
      transformOrigin: 'left',
      scaleX: 0,
    },
    {
      transformOrigin: 'left',
      scaleX: 0.5,
    },
    2
  )
}

export function News(el) {
  let title = el.querySelector('.title')
  let sub_title = el.querySelector('.sub_title')
  let more_btn = el.querySelectorAll('.more_btn')
  let cards = el.querySelectorAll('.card')
  let news_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top center',
      toggleActions: 'restart play play reverse',
    },
  })

  news_tl.fromTo(
    title,
    {
      y: '110%',
    },
    {
      y: '0%',
      duration: 0.3,
    },
    0
  )
  news_tl.fromTo(
    sub_title,
    {
      y: '110%',
    },
    {
      y: '0%',
      delay: 0.3,
    },
    0
  )
  news_tl.fromTo(
    cards,
    {
      y: '30%',
      opacity: 0,
    },
    {
      y: '0%',
      opacity: 1,
      stagger: 0.1,
      delay: 0.6,
    },
    0
  )
  news_tl.fromTo(
    more_btn,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.8,
    },
    0
  )
}
export function NewsCarousel(el, cards, index) {
  let width = cards[0].clientWidth
  gsap.to(el, {
    x: `${width * index * -1}px`,
  })
}

export function Video(el) {
  let title = el.querySelector('.title')
  let cover = el.querySelector('.cover')
  let more_btn = el.querySelector('.more_btn')

  let scroll_tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top center',
      toggleActions: 'restart play play reverse',
    },
  })
  scroll_tl.fromTo(
    cover,
    {
      opacity: 0,
      webkitFilter: 'brightness(1)',
      filter: 'brightness(1)',
    },
    {
      opacity: 1,
      webkitFilter: 'brightness(3)',
      filter: 'brightness(3)',
      onComplete: () => {
        scroll_tl.fromTo(
          cover,
          {
            webkitFilter: 'brightness(3)',
            filter: 'brightness(3)',
          },
          {
            webkitFilter: 'brightness(1)',
            filter: 'brightness(1)',
          },
          0
        )
      },
    },
    0
  )

  scroll_tl.fromTo(
    title,
    {
      opacity: 0,
      y: '30%',
    },
    {
      opacity: 1,
      y: '0%',
    },
    0
  )
  scroll_tl.fromTo(
    more_btn,
    {
      opacity: 0,
      x: '-30%',
    },
    {
      opacity: 1,
      x: '0%',
      delay: 0.3,
    },
    0
  )
}
