import store from '@/store/index';
import router from '@/router/index';

export function GetMetaData() {
  if (store.state.seo_meta == null) {
    return null;
  }
  let base_meta = store.state.seo_meta.filter(
    (item) => item.base_meta != '0'
  )[0];
  let match_meta = store.state.seo_meta.filter((item) => {
    return (
      window.location.pathname.indexOf(item.link) != -1 && item.base_meta != '1'
    );
  });
  match_meta.sort((a, b) => {
    return b.link.length - a.link.length;
  });
  match_meta = match_meta.length <= 0 ? base_meta : match_meta[0];

  match_meta.description.length > 155
    ? (match_meta.description = match_meta.description.slice(0, 155) + '...')
    : '';

  return {
    title: match_meta.title,
    meta: [
      {
        property: 'og:title',
        name: 'og:title',
        content: match_meta.title,
        vmid: 'og:title',
      },
      {
        property: 'og:url',
        name: 'og:url',
        content:
          process.env.VUE_APP_BASEURL.slice(0, -1) +
          router.history.current.fullPath,
        vmid: 'og:url',
      },
      {
        property: 'og:description',
        name: 'og:description',
        content: match_meta.description,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        name: 'twitter:description',
        content: match_meta.description,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        name: 'description',
        content: match_meta.description,
        vmid: 'description',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: match_meta.image == '' ? base_meta.image : match_meta.image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: match_meta.title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        name: 'application-name',
        content: match_meta.title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        name: 'og:site_name',
        content: match_meta.title,
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        name: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}

export function SetMetaData(title, description, image) {
  let base_meta = store.state.seo_meta.filter(
    (item) => item.base_meta != '0'
  )[0];

  description.length > 155
    ? (description = description.slice(0, 155) + '...')
    : '';
  return {
    title: title + ' | ' + base_meta.title,
    meta: [
      {
        property: 'og:title',
        name: 'og:title',
        content: title + ' | ' + base_meta.title,
        vmid: 'og:title',
      },
      {
        property: 'og:url',
        name: 'og:url',
        content:
          process.env.VUE_APP_BASEURL.slice(0, -1) +
          router.history.current.fullPath,
        vmid: 'og:url',
      },
      {
        property: 'og:description',
        name: 'og:description',
        content: description,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        name: 'twitter:description',
        content: description,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        name: 'description',
        content: description,
        vmid: 'description',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        name: 'application-name',
        content: title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        name: 'og:site_name',
        content: title,
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        name: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}
