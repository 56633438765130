import { get, post_form } from '@/common/request';

//首頁資料
export function getHomePage() {
  return get('home/get');
}
//關於我們資料
export function getAboutPage() {
  return get('about/get');
}

//商品資料
export function getProductData() {
  // return get('product/get');
  var page_data = get('product_page/get');
  var product = get('product/get');

  let promise_list = [product, page_data];

  return Promise.all(GetPromise(promise_list)).then(
    (res) => {
      if (promise_list.length == res.length) {
        return res;
      }
    },
    (err) => console.log(err)
  );
}
// 最新消息
export function getNewsData() {
  return get('news_list/get');
}
// 影片
export function getVideoData() {
  return get('video/get');
}
// Footer資訊
export function getFooterData() {
  return get('footer/get');
}
//經銷資訊
export function getDealerData() {
  return get('dealer/get');
}
// 搜尋
export function getScooterData() {
  return get('scooter/get');
}

export function getPayment() {
  return get('shop_cart/get');
}

export function getSeoMeta() {
  return get('seo_meta/get');
}

export function sendCheckout(form_data, shop_cart) {
  let checkout_form = new FormData();
  checkout_form.append('form_data', JSON.stringify(form_data));
  checkout_form.append('shop_cart', JSON.stringify(shop_cart));
  return post_form('shop_cart/create', checkout_form);
}

//聯絡我們
export function sendContact(data) {
  let contact_form = new FormData();
  contact_form.append('form_data', JSON.stringify(data));
  return post_form('contact/send', contact_form);
}

export function get_all_data() {
  //分類
  var category = get('admin/advertisement/category');
  //廣告
  var advertisement = get('admin/advertisement');

  let promise_list = [category, advertisement];

  return Promise.all(GetPromise(promise_list)).then(
    (res) => {
      if (promise_list.length == res.length) {
        return res;
      }
    },
    (err) => console.log(err)
  );
}

function GetPromise(promiseList) {
  return promiseList.map((promise) => promise.then((res) => res));
}
