<template>
  <li
    class="relative flex flex-wrap items-stretch w-full pb-5 mb-5 border-b-2 border-black md:flex-nowrap"
  >
    <div class="w-1/3 px-2 md:w-1/4">
      <img
        :alt="product_data.title"
        :src="product_data.cover_image"
        class="block w-full"
      />
    </div>
    <div class="flex flex-wrap w-2/3 md:w-3/4">
      <div class="md:w-[160px] w-full flex-1 flex flex-col justify-center px-5">
        <h4 class="text-lg font-bold">{{ product_data.title }}</h4>
        <h4 class="text-xs font-bold">
          {{ product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝配件' }}
        </h4>
        <p class="mt-2 text-xs text-zinc-500">{{ GetOptionStr() }}</p>
      </div>
      <div
        class="md:w-[119px] w-full flex flex-col justify-end md:items-end md:px-0 px-5 md:mt-0 mt-3 relative"
      >
        <div class="flex items-stretch w-full md:justify-end">
          <button @click="RemoveProduct(1)" class="px-4 bg-white">-</button>
          <input
            type="text"
            :value="shop_cart_data.amount"
            readonly
            class="w-8 py-[2px] text-center focus:outline-none"
          />
          <button @click="AddProduct" class="px-4 bg-white">+</button>
        </div>
        <h4 class="mt-2 text-main-color">TWD ${{ GetPrice() | money }}</h4>
      </div>
    </div>

    <button
      @click="RemoveProduct(-1)"
      class="absolute top-0 right-0 transition-all duration-300 transform hover:text-main-color hover:scale-150"
    >
      <Close class="w-3" />
    </button>
  </li>
</template>

<script>
import { MoneyFormat } from '@/common/filter';
import Close from '@/components/svg/Close.vue';
import { AddShopCart, RemoveShopCart } from '@/common/shop_cart';
import { ConvertACCYData } from '@/common/gtm_methods';
export default {
  name: 'ShopCartDrawerListItem',
  props: {
    product_data: {
      type: Object,
    },
    shop_cart_data: {
      type: Object,
    },
  },
  components: {
    Close,
  },
  methods: {
    GetPrice() {
      let price = parseInt(this.product_data.price);
      this.shop_cart_data.options.forEach((item) => {
        price += parseInt(item.price);
      });
      return price;
    },
    GetOptionStr() {
      let str = '';
      this.shop_cart_data.options.forEach((item, item_index) => {
        item_index > 0 ? (str += ' / ') : '';
        str += item.value;
      });

      return str;
    },
    AddProduct() {
      window.dataLayer.push({
        event: 'add_to_cart',
        items: [
          ConvertACCYData(this.product_data, this.shop_cart_data.options, 1),
        ],
        value: 0,
        currency: 'TWD',
      });
      AddShopCart(this.shop_cart_data);
    },
    RemoveProduct(count) {
      window.dataLayer.push({
        event: 'remove_from_cart',
        items: [
          ConvertACCYData(
            this.product_data,
            this.shop_cart_data.options,
            count != -1 ? count : this.shop_cart_data.amount
          ),
        ],
        value: 0,
        currency: 'TWD',
      });
      RemoveShopCart(this.shop_cart_data, count);
    },
  },
  filters: {
    money(val) {
      return MoneyFormat(val);
    },
  },
};
</script>
