<template>
  <div
    ref="MainContent"
    id="MainMenu"
    class="fixed top-0 -left-full w-full h-full z-[31] bg-main-color"
  >
    <MoreButton
      @click="Close"
      class="absolute pl-0 ml-0 text-white xs:top-10 top-3 left-5 header"
      :reverse="true"
      text="CLOSE"
      arrow="left"
    />
    <ol
      class="absolute flex items-center justify-center text-white header xs:top-10 top-4 right-5"
    >
      <li class="px-2">
        <router-link @click.native="Close" to="/contact">
          <Mail class="w-7" />
        </router-link>
      </li>
      <li class="px-2">
        <router-link
          @click.native="Close"
          to="/shop_cart/list"
          class="relative"
        >
          <span
            class="absolute z-10 block w-4 h-4 text-xs text-center bg-white rounded-full -top-2 -right-2 text-main-color"
          >
            <b class="block transform scale-[.7] font-normal">2</b>
          </span>
          <Cart class="relative z-0 w-5" />
        </router-link>
      </li>
    </ol>

    <div class="flex flex-wrap items-stretch justify-start w-full h-full pt-32">
      <div class="flex flex-col items-center w-1/2 h-2/3 left">
        <router-link @click.native="Close" to="/" class="">
          <Logo class="w-12 text-white sm:w-20 xs:w-14" />
        </router-link>
        <div class="pb-10 sm:mt-0 xs:mt-24 mt-14">
          <div class="flex flex-col items-center justify-center w-full">
            <p class="text-white social_text">FOLLOW</p>
            <span class="w-[1px] sm:h-28 h-10 my-4 block bg-white"></span>
          </div>
          <div
            v-if="footer_data != null"
            class="flex flex-col items-center justify-start sm:flex-row sm:mt-3"
          >
            <a
              target="_blank"
              :href="footer_data.facebook.content"
              class="block mb-3 sm:mr-2 sm:mb-0"
            >
              <Facebook class="w-5 text-white sm:w-6" />
            </a>
            <a
              target="_blank"
              :href="footer_data.instagram.content"
              class="block sm:ml-2"
            >
              <Instagram class="w-5 text-white sm:w-6" />
            </a>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-1/2 h-2/3">
        <ol>
          <li
            v-for="item in menu_list"
            class="mb-4 xs:mb-6 menu_item"
            :key="item.title"
          >
            <router-link
              @click.native="Close"
              class="text-white"
              :to="item.url"
            >
              <b class="block text-base uppercase xs:text-xl">{{
                item.title
              }}</b>
              <span class="block text-xs xs:text-sm">{{ item.sub_title }}</span>
            </router-link>
          </li>
        </ol>
      </div>
      <div class="flex items-stretch w-full mt-auto h-1/3">
        <router-link
          @click.native="Close"
          to="/products"
          class="relative flex items-end w-1/2 h-full pb-5 pl-5 overflow-hidden bottom sm:items-center sm:justify-center sm:pl-0 sm:pb-0"
        >
          <div class="relative z-10">
            <p class="font-bold text-white sm:text-2xl">FORGED<br />WHEELS</p>
            <MoreButton
              text="鍛造輪框"
              class="inline-flex pb-0 pl-0 ml-0 text-white sm:mt-2"
            />
          </div>
          <div
            class="absolute top-0 left-0 w-full h-full z-[1] bg-main-color mix-blend-color"
          ></div>
          <img
            alt="DEGO 偉士牌鍛造輪框"
            src="/img/menu/menu_image_1.webp"
            class="absolute top-0 left-0 w-full h-full object-cover z-0 brightness-[.3]"
          />
        </router-link>

        <router-link
          @click.native="Close"
          to="/products"
          class="relative flex items-end w-1/2 h-full pb-5 pl-5 overflow-hidden bottom sm:items-center sm:justify-center sm:pl-0 sm:pb-0"
        >
          <div class="relative z-10">
            <p class="font-bold text-white uppercase sm:text-2xl">
              vehicle<br />
              merchandise
            </p>
            <MoreButton
              text="偉士牌周邊商品"
              class="inline-flex pb-0 pl-0 ml-0 text-white sm:mt-2"
            />
          </div>
          <div
            class="absolute top-0 left-0 w-full h-full z-[1] bg-[#E0DBC7] mix-blend-color"
          ></div>
          <img
            alt="DEGO 偉士牌改裝精品"
            src="/img/menu/menu_image_2.webp"
            class="absolute top-0 left-0 w-full h-full object-cover z-0 brightness-[.3]"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Mail from './svg/Mail.vue';
import Cart from './svg/Cart.vue';
import MoreButton from './ui/MoreButton.vue';
import Logo from './svg/Logo.vue';
import Facebook from './svg/Facebook.vue';
import Instagram from './svg/Instagram.vue';
import { MenuActive, MenuClose } from '@/gsap/header';
export default {
  name: 'MainMenu',
  components: {
    Mail,
    Cart,
    MoreButton,
    Logo,
    Facebook,
    Instagram,
  },
  data() {
    return {
      menu_list: [
        {
          title: 'Products',
          sub_title: '商品列表',
          url: '/product_list',
        },
        {
          title: 'Customize',
          sub_title: '客製選色',
          url: '/customize',
        },
        {
          title: 'About',
          sub_title: '關於我們',
          url: '/about',
        },
        {
          title: 'News',
          sub_title: '最新消息',
          url: '/news_list',
        },
        {
          title: 'Video',
          sub_title: '影音專區',
          url: '/video_list',
        },
        {
          title: 'Dealers',
          sub_title: '經銷據點',
          url: '/dealers/list',
        },
      ],
    };
  },
  methods: {
    Active() {
      MenuActive(this.$refs.MainContent, this.menu_tl);
    },
    Close() {
      MenuClose();
    },
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  #MainMenu .social_text {
    writing-mode: vertical-lr;
  }
}
</style>
