import { gsap } from './gsap_loader';
var loading_tl = null;
var un_loading_tl = null;
var out_side_circle_tl = null;
var in_side_circle_tl = null;
var text_circle_tl = null;
var main_circle_tl = null;

export function Loading(el) {
  let svg = el.querySelector('svg');
  loading_tl != null ? loading_tl.kill() : '';
  loading_tl = gsap.timeline();

  loading_tl.set(
    el,
    {
      x: '100%',
    },
    0
  );
  loading_tl.set(
    el,
    {
      opacity: 1,
    },
    0
  );
  loading_tl.fromTo(
    svg,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      delay: 0.6,
    },
    0
  );

  loading_tl.add(OutSideCircle(el), 1);
  loading_tl.add(InSideCircle(el), 1);
  loading_tl.add(TextCircle(el), 1);
  loading_tl.add(MainCircle(el), 1);
}

export function UnLoading(el) {
  let svg = el.querySelector('svg');
  un_loading_tl != null ? un_loading_tl.kill() : '';
  un_loading_tl = gsap.timeline();
  un_loading_tl.fromTo(
    svg,
    {
      opacity: 1,
    },
    {
      opacity: 0,
      duration: 0.2,
    },
    0
  );

  un_loading_tl.fromTo(
    el,
    {
      opacity: 1,
    },
    {
      opacity: 0,
      duration: 0.2,
      delay: 0.2,
    },
    0
  );

  un_loading_tl.set(
    el,
    {
      x: '0%',
      delay: 0.4,
      onComplete: () => {
        loading_tl.kill();
        un_loading_tl.kill();
        out_side_circle_tl.kill();
        in_side_circle_tl.kill();
        text_circle_tl.kill();
        main_circle_tl.kill();
      },
    },
    0
  );
}

function OutSideCircle(el) {
  out_side_circle_tl != null ? out_side_circle_tl.kill() : '';
  out_side_circle_tl = gsap.timeline({
    repeat: -1,
  });
  var circle = el.querySelector('#outside_circle');
  var radius = circle.r.baseVal.value;
  var circumference = radius * 2 * Math.PI;

  circle.style.strokeDasharray = `${circumference} ${circumference}`;
  circle.style.strokeDashoffset = `${circumference}`;

  const start_offset = circumference - (0 / 100) * circumference;
  const end_offset = circumference - (100 / 100) * circumference;

  out_side_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
      ease: 'expo.inOut',
      duration: 2,
    },
    0
  );
  out_side_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
      ease: 'expo.inOut',
      delay: 2,
      duration: 2,
    },
    0
  );
  out_side_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
      ease: 'expo.inOut',
      delay: 4,
      duration: 2,
    },
    0
  );
  out_side_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
      ease: 'expo.inOut',
      delay: 6,
      duration: 2,
    },
    0
  );
  return out_side_circle_tl;
}

function InSideCircle(el) {
  in_side_circle_tl != null ? in_side_circle_tl.kill() : '';
  in_side_circle_tl = gsap.timeline({
    repeat: -1,
  });
  var circle = el.querySelector('#inside_circle');
  var radius = circle.r.baseVal.value;
  var circumference = radius * 2 * Math.PI;

  circle.style.strokeDasharray = `${circumference} ${circumference}`;
  circle.style.strokeDashoffset = `${circumference}`;

  const start_offset = circumference - (0 / 100) * circumference;
  const end_offset = circumference - (100 / 100) * circumference;

  in_side_circle_tl.fromTo(
    circle,
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
    },
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
      ease: 'expo.inOut',
      duration: 2,
    },
    0
  );
  in_side_circle_tl.fromTo(
    circle,
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
    },
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
      ease: 'expo.inOut',
      delay: 2,
      duration: 2,
    },
    0
  );
  in_side_circle_tl.fromTo(
    circle,
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
    },
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
      ease: 'expo.inOut',
      delay: 4,
      duration: 2,
    },
    0
  );
  in_side_circle_tl.fromTo(
    circle,
    {
      rotation: 360,
      transformOrigin: 'center',
      strokeDashoffset: end_offset,
    },
    {
      rotation: 0,
      transformOrigin: 'center',
      strokeDashoffset: start_offset,
      ease: 'expo.inOut',
      delay: 6,
      duration: 2,
    },
    0
  );
  return in_side_circle_tl;
}

function TextCircle(el) {
  text_circle_tl != null ? text_circle_tl.kill() : '';
  text_circle_tl = gsap.timeline({
    repeat: -1,
  });
  var circle = el.querySelector('#text_circle');

  text_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      ease: 'linear',
      duration: 2,
    },
    0
  );
  return text_circle_tl;
}

function MainCircle(el) {
  main_circle_tl != null ? main_circle_tl.kill() : '';
  main_circle_tl = gsap.timeline({
    repeat: -1,
    repeatDelay: 3,
    repeatRefresh: true,
  });
  var circle = el.querySelector('#main_circle');
  var scooter = el.querySelector('#scooter');
  var logo_icon = el.querySelector('#logo_icon');
  var text_cover = el.querySelectorAll('#text_cover rect');

  main_circle_tl.fromTo(
    circle,
    {
      rotation: 0,
      transformOrigin: 'center',
    },
    {
      rotation: 360,
      transformOrigin: 'center',
      ease: 'expo.inOut',
      duration: 1.5,
    },
    0
  );
  main_circle_tl.fromTo(
    scooter,
    {
      x: '105%',
    },
    {
      x: '0%',
      duration: 0.4,
      ease: 'back.inOut(1.7)',
      delay: 1.5,
    },
    0
  );
  main_circle_tl.fromTo(
    logo_icon,
    {
      y: '105%',
    },
    {
      y: '0%',
      duration: 0.4,
      ease: 'back.inOut(1.7)',
      delay: 1.5,
    },
    0
  );
  main_circle_tl.fromTo(
    text_cover,
    {
      opacity: 1,
    },
    {
      opacity: 0,
      stagger: 0.1,
      duration: 0.4,
      delay: 1.5,
    },
    0
  );
  return main_circle_tl;
}
