<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div
    id="MainLoading"
    ref="MainContent"
    class="fixed top-0 -left-full w-full h-full z-40 flex items-center justify-center"
  >
    <svg class="relative z-10 w-56" viewBox="0 0 200 200">
      <g id="six_angle">
        <path
          class="st0"
          d="M68.4,36.5l-2.5-4.3h-5l-2.5,4.3l2.5,4.3h5L68.4,36.5z M63.3,39c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5
		s2.5,1.1,2.5,2.5C65.9,37.9,64.7,39,63.3,39z"
        />
        <path
          class="st0"
          d="M63.4,34.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8C65.2,35.5,64.4,34.7,63.4,34.7z"
        />
        <path
          class="st0"
          d="M134.2,32.1l-2.5,4.3l2.5,4.3h5l2.5-4.3l-2.5-4.3H134.2z M136.7,39c-1.4,0-2.5-1.1-2.5-2.5
		c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C139.3,37.9,138.1,39,136.7,39z"
        />
        <path
          class="st0"
          d="M136.7,34.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C138.6,35.5,137.8,34.7,136.7,34.7z
		"
        />
        <path
          class="st0"
          d="M178.4,100l-2.5-4.3h-5l-2.5,4.3l2.5,4.3h5L178.4,100z M173.4,102.6c-1.4,0-2.5-1.1-2.5-2.5
		c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C176,101.4,174.8,102.6,173.4,102.6z"
        />
        <path
          class="st0"
          d="M173.4,98.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8C175.3,99,174.5,98.2,173.4,98.2z"
        />
        <path
          class="st0"
          d="M131.7,163.6l2.5,4.3h5l2.5-4.3l-2.5-4.3h-5L131.7,163.6z M136.8,161.1c1.4,0,2.5,1.1,2.5,2.5
		c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5C134.2,162.2,135.3,161.1,136.8,161.1z"
        />
        <path
          class="st0"
          d="M136.7,165.4c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8
		C134.9,164.6,135.7,165.4,136.7,165.4z"
        />
        <path
          class="st0"
          d="M58.3,163.6l2.5,4.3h5l2.5-4.3l-2.5-4.3h-5L58.3,163.6z M63.4,161.1c1.4,0,2.5,1.1,2.5,2.5
		c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5C60.8,162.2,62,161.1,63.4,161.1z"
        />
        <path
          class="st0"
          d="M63.4,165.4c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C61.5,164.6,62.3,165.4,63.4,165.4z"
        />
        <path
          class="st0"
          d="M21.6,100l2.5,4.3h5l2.5-4.3l-2.5-4.3h-5L21.6,100z M26.6,97.5c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5
		c-1.4,0-2.5-1.1-2.5-2.5C24.1,98.7,25.3,97.5,26.6,97.5z"
        />
        <path
          class="st0"
          d="M26.6,101.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C24.8,101,25.6,101.9,26.6,101.9z"
        />
      </g>
      <g id="vespa_text">
        <path
          class="st0"
          d="M85.6,168.2l-0.4,7.3l1.8,0.3l5.1-6.5l-1.6-0.3l-3.7,4.9l0,0l0.5-5.4L85.6,168.2z"
        />
        <path
          class="st0"
          d="M94.2,175.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
		c-0.4,0-0.7-0.2-0.8-0.4c-0.2-0.2-0.3-0.4-0.2-0.8l0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l3.9,0.3c0.1-0.3,0.1-0.6,0.2-0.8
		c0-0.4,0-0.7-0.1-1c-0.1-0.3-0.2-0.5-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.9,0-1.2,0.2
		c-0.4,0.1-0.7,0.4-1,0.6c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.4,0,0.7,0.1,1c0.1,0.3,0.2,0.5,0.4,0.7
		c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.7,0,1,0c0.3,0,0.6-0.1,0.8-0.3c0.2-0.1,0.4-0.3,0.6-0.5
		c0.2-0.2,0.3-0.5,0.5-0.7L94.2,175.1L94.2,175.1z M92.5,173c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3-0.1,0.5,0
		c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.4s0,0.3,0,0.4l-2.4-0.2C92.4,173.2,92.4,173.1,92.5,173
		L92.5,173z"
        />
        <path
          class="st0"
          d="M99.5,172.6c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5l1.3,0
		c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3,0-0.5-0.1-0.8-0.1c-0.3,0-0.5,0-0.8,0.1
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.2,0.5-0.2,0.8c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.3,0.2,0.4
		c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2l1.1,0.4c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2-0.1,0.3
		c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1,0-0.2-0.1-0.3-0.2c0-0.1-0.1-0.2-0.1-0.3v-0.2l-1.3,0v0.2c0,0.3,0,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5
		c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0.3,0,0.6,0,0.9-0.1c0.3,0,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.3,0.6-0.6
		c0.2-0.2,0.2-0.5,0.2-0.8c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.5-0.2
		l-0.7-0.3c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1-0.1-0.1-0.2-0.1-0.3C99.2,172.8,99.3,172.7,99.5,172.6L99.5,172.6z"
        />
        <path
          class="st0"
          d="M108.3,171.7c-0.4-0.3-0.9-0.5-1.5-0.4c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.1-0.5,0.4-0.6,0.7l0,0l0.1-0.7l-1.4,0.1
		l-0.9,7.2l1.4-0.1l0.3-2.6l0,0c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5,0
		c0.5,0,0.9-0.2,1.2-0.4c0.3-0.2,0.6-0.5,0.8-0.9c0.2-0.3,0.3-0.7,0.4-1.1c0.1-0.4,0.1-0.9,0.1-1.3
		C108.9,172.5,108.7,172,108.3,171.7L108.3,171.7z M107.5,174.2c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.2,0-0.5,0-0.7c0-0.3,0.1-0.5,0.2-0.7
		c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.3c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4
		c0.1,0.1,0.1,0.3,0.1,0.4C107.6,173.7,107.6,173.9,107.5,174.2L107.5,174.2z"
        />
        <path
          class="st0"
          d="M113.6,172.5c-0.2,0.1-0.3,0.1-0.6,0.2c-0.2,0.1-0.5,0.1-0.9,0.3c-0.6,0.2-1.1,0.5-1.4,0.8
		c-0.3,0.4-0.4,0.8-0.3,1.3c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5,0,0.8,0
		c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.4,0.6-0.6l0.1,0.5l1.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0-0.4
		l0.1-2.2c0-0.4,0-0.7,0-0.9c0-0.3-0.2-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0-0.5,0-0.7,0.1
		c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9l1.3-0.2
		c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.2
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.3,0,0.4C113.9,172.3,113.8,172.4,113.6,172.5L113.6,172.5z M113.9,173.7c0,0.1,0,0.3-0.1,0.4
		c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.3-0.2-0.3-0.4
		c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.4-0.1,0.6-0.2
		c0.2-0.1,0.4-0.2,0.6-0.3L113.9,173.7L113.9,173.7z"
        />
      </g>
      <g id="main_circle">
        <g class="overflow-hidden">
          <path
            class="st0"
            d="M99.8,81.6V33.3c0,0-24.4-1.2-44.4,17.3c0,0-12.7,9.5-19.2,30.5l-0.1,0.4L99.8,81.6L99.8,81.6z"
          />

          <path
            id="logo_icon"
            class="st1 transfrom translate-y-full"
            d="M60.5,78v3.6H62v-2.7l4.5,2.6v0.1h1.5v-8.5c0-0.8,0.1-1.6,0.3-2.4c0.2-0.8,0.5-1.6,1-2.4
	c0.4-0.6,0.8-1.2,1.2-1.7c0,0,0-0.1,0.1-0.1c0.6-0.6,1.2-1.2,1.9-1.7v16.7h1.5V64.8c0.3,0.2,0.5,0.4,0.8,0.6
	c0.4,0.4,0.8,0.7,1.2,1.2c0.4,0.5,0.8,1,1.2,1.6c0.4,0.8,0.8,1.6,1,2.5c0.2,0.8,0.3,1.6,0.3,2.4v0.4c0,0.1,0,0.2,0,0.2v0.1v7v0.7
	h1.5v-0.2h0l4.5-2.6v2.8h1.5v-3.7l0.4-0.3c3.5-2.2,5.7-5.9,5.7-10.1c0-0.2,0-0.4,0-0.6c0-0.9-0.2-1.9-0.5-2.8
	c0.4-1.1,0.6-2.3,0.6-3.6c0-0.2,0-0.4,0-0.6c-0.1-1-0.2-2-0.5-2.9c0.3-1.1,0.5-2.3,0.5-3.4c0-0.2,0-0.4,0-0.6
	c-0.1-2.5-1-4.8-2.6-6.8c-0.2-0.3-0.7-0.4-1-0.2c-0.3,0.2-0.5,0.6-0.3,0.9c0.3,0.8,0.4,1.6,0.4,2.5c0,2.6-1.3,4.9-3.5,6.2L79,59.3
	c-1.2-1.5-1.8-3.5-1.8-5.4c0-0.3,0-0.7,0.1-1c0.2-0.6,0.7-1,1.3-1.1c0.6-0.1,1.3,0.2,1.6,0.7c0.2,0.3,0.6,0.4,0.9,0.3
	c0.3-0.1,0.5-0.5,0.5-0.8c-0.3-2.3-1.9-4.4-4-5.2s-4.8-0.3-6.5,1.2c-0.3,0.2-0.5,0.5-0.7,0.7c-0.3,0.3-0.6,0.6-0.9,0.9
	c-0.6,0.4-1.2,0.6-1.8,0.4c-0.7-0.2-1-0.7-1.2-1.1c-0.1-0.3-0.4-0.5-0.8-0.5c-0.3,0-0.6,0.3-0.7,0.6c-0.3,1.2,0.3,2.7,1,3.5
	c0.7,0.8,1.6,1.3,2.6,1.4c0,1.9-0.6,3.8-1.7,5.3l-5.9-3.4c-2.2-1.3-3.5-3.7-3.5-6.2c0-0.9,0.1-1.7,0.4-2.5c0.1-0.4,0-0.8-0.3-0.9
	c-0.3-0.2-0.7-0.1-1,0.2c-1.5,1.9-2.4,4.3-2.6,6.7c0,0.2,0,0.4,0,0.6c0,1.2,0.2,2.3,0.5,3.4c-0.3,1-0.5,1.9-0.5,2.9
	c0,0.2,0,0.4,0,0.6c0,1.2,0.2,2.4,0.5,3.5c-0.3,0.9-0.5,1.9-0.5,2.8c0,0.2,0,0.4,0,0.6c0,4.2,2.1,7.9,5.7,10.1L60.5,78z M85.9,76.3
	l-5.7,3.3v-5.2l6.5-3.8c0.3-0.2,0.5-0.3,0.8-0.5c0.1,0,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0,0,0.1-0.1,0.1-0.1
	c0.2-0.2,0.4-0.4,0.7-0.6c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.5-0.7
	c0,0,0-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5C90.8,71.1,88.9,74.4,85.9,76.3L85.9,76.3z
	 M90.8,60.5c0,1.1-0.2,2.2-0.5,3.3c-0.8,2.3-2.3,4.2-4.4,5.5L85,69.8l-0.1,0l-4.8,2.8c0-0.8-0.2-1.6-0.4-2.3
	c-0.2-0.7-0.5-1.5-0.8-2.2l7.7-4.5c0.3-0.2,0.5-0.3,0.8-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.1,0,0.1-0.1,0.2-0.1
	c0.2-0.2,0.4-0.4,0.7-0.6c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.5-0.8
	c0,0,0,0,0,0c0.1-0.2,0.3-0.4,0.4-0.6c0,0.2,0,0.4,0,0.5C90.8,60.1,90.8,60.3,90.8,60.5L90.8,60.5z M86.1,57
	c2.3-1.4,3.9-3.8,4.2-6.5c0.3,0.8,0.4,1.6,0.4,2.5c0,0.2,0,0.4,0,0.5c0,1.1-0.2,2.2-0.5,3.2c-0.8,2.3-2.3,4.3-4.4,5.6l-7.7,4.4
	c-0.3-0.4-0.6-0.8-0.9-1.2c-0.4-0.5-0.9-0.9-1.4-1.3c-0.3-0.3-0.7-0.5-1-0.8l1.2-0.7L86.1,57L86.1,57z M70.2,53
	c0-0.4-0.4-0.7-0.8-0.7c-0.8,0-1.5-0.3-2.1-0.8c1,0.2,2.1,0,3.1-0.7c0.4-0.3,0.8-0.7,1.2-1.1c0.2-0.2,0.4-0.4,0.6-0.6
	c1.3-1.2,3.3-1.5,4.9-0.9c1,0.4,1.9,1.2,2.4,2.1c-0.4-0.1-0.7-0.1-1.1,0c-1.2,0.2-2.2,1-2.6,2.2c-0.2,0.5-0.2,1.1-0.2,1.5
	c0,2.2,0.7,4.4,2,6.2l-2.6,1.5l-1,0.6L74,62.2l-0.3,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1l-0.2,0.1l-0.1-0.1L73,62.5
	c0,0-0.1-0.1-0.1-0.1l-4.5-2.6C69.7,57.9,70.4,55.4,70.2,53L70.2,53z M55.7,53c0-0.9,0.2-1.7,0.4-2.5c0.3,2.7,1.8,5,4.2,6.5l6.5,3.7
	l4.5,2.6l0.3,0.2l0.1,0.1c-0.8,0.5-1.5,1.2-2.1,1.9c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.4,0.4-0.7,0.8-1,1.3l-0.1,0l-2.5-1.5
	l-5.3-3c-0.3-0.2-0.5-0.3-0.8-0.5c-1.7-1.3-3-3-3.7-5.1c-0.3-1-0.5-2.1-0.5-3.2C55.6,53.3,55.6,53.2,55.7,53L55.7,53z M55.6,59.9
	c0-0.2,0-0.4,0-0.5c0.1,0.2,0.2,0.4,0.4,0.6c0,0,0,0,0,0c0.2,0.3,0.4,0.5,0.5,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.4,0.5,0.6
	c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.4,0.4,0.7,0.6c0,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.1,0.1,0.2,0.2
	c0.3,0.2,0.5,0.4,0.8,0.5l7.9,4.6c-0.3,0.7-0.6,1.3-0.8,2c-0.2,0.8-0.4,1.7-0.4,2.5l-4.1-2.4l-1.3-0.7c0,0,0,0,0,0l-0.5-0.3
	c-1-0.6-1.9-1.4-2.7-2.4c-0.8-0.9-1.3-2-1.7-3.1c-0.4-1.1-0.5-2.2-0.5-3.3C55.6,60.3,55.6,60.1,55.6,59.9L55.6,59.9z M55.6,67.5
	c0-0.2,0-0.4,0-0.5c0-0.2,0-0.3,0-0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.4,0.5,0.6,0.8
	c0.9,1.1,1.9,2,3.1,2.7l0.5,0.3c0,0,0,0,0,0l6.2,3.6v5.2l-1.5-0.9l-3.4-2h0c0,0,0,0-0.1,0l-1-0.6C57.5,74.4,55.6,71.1,55.6,67.5
	L55.6,67.5z"
          />
        </g>
        <path
          class="st0"
          d="M100,33.3c-16.1,0-30.9,5.7-42.5,15.3c-1.2,1-2.3,2-3.4,3c-9.8,9.2-16.7,21.3-19.5,35c0,0.1,0,0.1,0,0.2
		c-0.9,4.3-1.3,8.8-1.3,13.3c0,4.6,0.5,9,1.3,13.3c0,0,0,0.1,0,0.2c0.4,2,0.9,3.9,1.5,5.8c8.3,27.3,33.6,47.2,63.5,47.4
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c13.5-0.1,26.1-4.2,36.5-11.2c1.7-1.1,3.4-2.4,5-3.7l0,0h0c0.2-0.2,0.5-0.4,0.7-0.6
		c0.4-0.3,0.8-0.7,1.2-1.1l0.4-0.3c3.2-2.8,6.1-6,8.7-9.4c0.1-0.2,0.3-0.3,0.4-0.5c0.6-0.7,1.1-1.5,1.6-2.2c0.1-0.1,0.2-0.3,0.3-0.4
		c0.9-1.3,1.8-2.7,2.6-4.2c2.5-4.3,4.6-9,6-13.9c1.9-6.1,2.9-12.6,2.9-19.3C166.8,63.3,136.8,33.3,100,33.3L100,33.3z M165.7,96h-9
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9C165.7,95.5,165.7,95.7,165.7,96L165.7,96z M165.3,91.6h-9.4
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9.3C165.3,91,165.3,91.3,165.3,91.6L165.3,91.6z M164.6,87.2h-9.5
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9.4C164.5,86.6,164.6,86.9,164.6,87.2L164.6,87.2z M101.5,34.2c0.2,0,0.4,0,0.6,0
		c0.9,0,1.7,0.1,2.5,0.1c0.3,0,0.6,0,0.9,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0.1,1.6,0.2,2.4,0.3c0.6,0.1,1.1,0.2,1.7,0.3
		c0.3,0,0.7,0.1,1,0.2c0.5,0.1,1.1,0.2,1.6,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.6,0.1,1.2,0.3,1.9,0.4c0.9,0.2,1.7,0.4,2.6,0.7
		c0.7,0.2,1.5,0.4,2.2,0.7c0.1,0,0.2,0.1,0.3,0.1c1.7,0.6,3.3,1.2,4.9,1.9c1.6,0.7,3.2,1.5,4.7,2.3c0.8,0.4,1.5,0.8,2.3,1.3
		c3,1.7,5.8,3.7,8.4,5.9c0.7,0.6,1.5,1.2,2.2,1.9c0.5,0.4,1,0.9,1.5,1.4c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.7,0.7,1.1,1.1
		c0.6,0.6,1.2,1.2,1.8,1.9c0.3,0.3,0.6,0.7,0.9,1c0.8,0.9,1.5,1.8,2.2,2.7c0.5,0.6,1,1.2,1.4,1.9c0.1,0.2,0.3,0.4,0.4,0.6
		c0.2,0.3,0.4,0.6,0.7,1c0.6,0.9,1.1,1.7,1.7,2.6l-54.9-31.6C101.4,34.2,101.4,34.2,101.5,34.2z M36.5,82.8L36.5,82.8
		c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4h23.6v-3.5l-0.7-0.4c-3.6-2.2-5.7-6-5.7-10.1
		c0-0.2,0-0.4,0-0.6c0-1,0.2-1.9,0.5-2.8c-0.4-1.1-0.5-2.3-0.5-3.6c0-0.2,0-0.4,0-0.6c0-1,0.2-2,0.5-2.9c-0.3-1.1-0.5-2.3-0.5-3.4
		c0-0.2,0-0.4,0-0.5v0h0l0,0c0.3-0.3,0.5-0.5,0.8-0.7c0.3-0.3,0.5-0.5,0.8-0.7c0,0,0,0,0,0l0.5-0.4c0.3-0.3,0.5-0.5,0.8-0.7
		c0.2-0.2,0.4-0.3,0.6-0.5c0.1,2.4,1.4,4.6,3.5,5.8l5.9,3.4c1.1-1.5,1.7-3.4,1.7-5.3c-1-0.2-1.9-0.7-2.6-1.4c-0.7-0.8-1.3-2.3-1-3.5
		c0.1-0.3,0.4-0.6,0.7-0.6c0.3,0,0.7,0.2,0.8,0.5c0.2,0.4,0.5,1,1.2,1.1c0.6,0.1,1.3,0,1.8-0.4c0.3-0.2,0.6-0.5,0.9-0.9
		c0.2-0.2,0.4-0.5,0.7-0.7c1.7-1.6,4.3-2,6.5-1.2c2.2,0.8,3.8,2.9,4,5.2c0,0.4-0.2,0.7-0.5,0.8c-0.3,0.1-0.7,0-0.9-0.3
		c-0.3-0.5-1-0.8-1.6-0.7c-0.6,0.1-1.2,0.6-1.3,1.1c-0.1,0.3-0.1,0.7-0.1,1c0,1.9,0.7,3.9,1.8,5.4l6.3-3.7c2.2-1.3,3.5-3.7,3.5-6.3
		c0-0.9-0.1-1.7-0.4-2.5c-0.1-0.4,0-0.8,0.3-0.9c0.3-0.2,0.8-0.1,1,0.2c1.6,2,2.4,4.3,2.6,6.8c0,0.2,0,0.4,0,0.6
		c0,1.2-0.2,2.3-0.5,3.4c0.3,0.9,0.5,1.9,0.5,2.9c0,0.2,0,0.4,0,0.6c0,1.2-0.2,2.4-0.5,3.6c0.3,0.9,0.4,1.9,0.5,2.8
		c0,0.2,0,0.4,0,0.6c0,4.2-2.1,7.9-5.7,10.1l-0.4,0.3v3.7h13.4V34.2h0.8v0.5l56.6,32.5c0.3,0.5,0.6,1,0.8,1.5
		c0.2,0.5,0.5,0.9,0.7,1.4c0.5,1,1,2.1,1.5,3.2L100.5,39v1l60.3,34.6c0.2,0.5,0.4,0.9,0.5,1.4c0.2,0.5,0.4,1,0.6,1.5
		c0.3,0.7,0.5,1.5,0.8,2.2c0.2,0.6,0.4,1.1,0.5,1.7c0,0.1,0,0.1,0,0.2h0c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
		c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1h0c0,0.1,0.1,0.2,0.1,0.3h-3.6c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0H36.4
		C36.4,83.1,36.5,82.9,36.5,82.8z M141.6,95.1v10c0,1.8-0.7,3.3-2,4.4c-1.3,1.1-3.3,1.6-6.4,1.6c-3,0-5.1-0.5-6.4-1.6
		c-1.3-1.1-1.9-2.6-1.9-4.4v-10c0-1.8,0.6-3.3,1.9-4.4c1.2-1.1,3.3-1.6,6.4-1.6c3,0,5.1,0.5,6.4,1.6C141,91.8,141.6,93.3,141.6,95.1
		z M106.4,106.7c0.2,0.1,1,0.6,3.7,0.6h4.9v-5.9h-4.3v-3.3h7.6v12.5h-8.2c-1.3,0-2.4-0.1-3.3-0.3c-1-0.2-1.8-0.5-2.5-1
		c-0.7-0.5-1.2-1.2-1.4-2.1c-0.2-0.7-0.3-1.5-0.3-2.4v-9.5c0-2,0.5-3.5,1.6-4.5c1-1,2.7-1.4,5.3-1.4h7.3v3.3h-7.3
		c-2.2,0-2.9,0.4-3,0.5c-0.4,0.3-0.5,1-0.5,2v9.5C105.8,105.5,105.9,106.3,106.4,106.7z M93.9,98.2v3.3h-8.3v5.8h11v3.3H82.3V89.4
		h14.4v3.3h-11v5.5L93.9,98.2L93.9,98.2z M75,95.6v9c0,0.8-0.1,1.5-0.2,2.1c-0.2,0.8-0.6,1.6-1.1,2.2c-0.5,0.6-1.3,1.1-2.3,1.4
		c-0.9,0.3-2,0.4-3.3,0.4h-9.7V89.4h9.7c1.3,0,2.4,0.2,3.3,0.4c0.9,0.3,1.7,0.8,2.2,1.4c0.6,0.6,0.9,1.3,1.1,2.2
		C74.9,94.1,75,94.8,75,95.6z M35.7,86.3H45c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-9.5C35.5,86.9,35.6,86.6,35.7,86.3
		L35.7,86.3z M34.8,90.7h9.3c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-9.4C34.8,91.3,34.8,91,34.8,90.7L34.8,90.7z M34.4,95.2
		h9c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-9C34.3,95.7,34.4,95.5,34.4,95.2L34.4,95.2z M34.2,99.6h8.3
		c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-8.3V99.6L34.2,99.6z M34.4,104.9l0-0.8h9c0.2,0,0.4,0.2,0.4,0.4
		c0,0.2-0.2,0.4-0.4,0.4L34.4,104.9L34.4,104.9z M34.7,108.5h9.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-9.3
		C34.8,109.1,34.8,108.8,34.7,108.5L34.7,108.5z M35.7,113.8c-0.1-0.3-0.1-0.6-0.2-0.8H45c0.2,0,0.4,0.2,0.4,0.4
		c0,0.2-0.2,0.4-0.4,0.4L35.7,113.8L35.7,113.8z M113.2,161.9c-4.2,0-7.6-3.4-7.6-7.6c0-2.6,1.3-5,3.5-6.4c0.6,0,1.3-0.1,2-0.2
		c0.2,0,0.4-0.1,0.6-0.1l-2.2,2.6c-0.1,0.1-0.2,0.3-0.1,0.5c0,0,0,0.1,0.1,0.1h0c-0.2,0.2-0.3,0.5-0.3,0.8c0,0,0,0.1,0,0.2
		c-0.5,0.8-0.7,1.6-0.7,2.5c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7c0-1.2-0.5-2.4-1.3-3.3l1.1-1.6c0.1,0,0.1,0.1,0.2,0.1
		c0,0,0.1,0.1,0.2,0.2c0.4,0.4,1.1,1.2,2.3,1.8c0.3,0.9,0.5,1.8,0.5,2.8C120.9,158.5,117.5,161.9,113.2,161.9z M113.5,154.2
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-2.7-2.9c-0.2-0.3-0.2-0.7,0-0.9
		c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.5,0.2L113.5,154.2L113.5,154.2z M111.6,151.4c0.7-0.4,1.5-0.5,2.2-0.4
		c-0.2,0.3-0.5,0.9-1,1.7L111.6,151.4L111.6,151.4z M110.2,153.1l2.1,2.2c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.7-0.3
		c0.3-0.3,0.4-0.7,0.3-1.1l1.5-2.3c0.6,0.6,0.9,1.4,0.9,2.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3
		C110,153.9,110,153.5,110.2,153.1L110.2,153.1z M115.9,151.2c-0.3,0-0.5,0.3-0.5,0.3l-1.5,2.4l-0.7-0.7c0.5-0.9,1.2-1.9,1.3-2.1
		c0.2-0.3,0-0.6,0-0.7l0.4-0.6l0.2-0.1c0.3,0,1.3-0.1,2-0.1L115.9,151.2L115.9,151.2z M114.9,149.2
		C114.9,149.2,114.9,149.2,114.9,149.2l-0.1,0c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.1,0-0.2,0.1l-0.2,0.1c-0.3,0.1-0.7,0.3-1,0.4
		c-0.4,0.2-0.7,0.3-1,0.4c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.5-0.1-0.5-0.1c0,0,0-0.1,0-0.1l2.5-2.9c1.5-0.1,2.7,0.2,3.2,0.5
		c0.5,0.3,1.1,0.7,1.6,1.1l0,0C116.5,149,115,149.1,114.9,149.2L114.9,149.2z M118.4,149.4c-0.1-0.1-0.2-0.2-0.2-0.2
		c-0.4-0.4-1.5-1.1-2.3-1.6c-0.6-0.3-1.7-0.6-3-0.6c-0.6,0-1.2,0.1-1.9,0.2c-2.2,0.4-3.1,0.1-3.2,0.1c0,0,0,0,0,0
		c0-0.1,0.1-0.3,0.2-0.4c1.1-1.6,4-4.1,6.2-4.5c0.3,0,0.6-0.1,0.9-0.2c0.3,0.1,0.5,0.1,0.7,0c0.8,0.4,2.2,1.9,2.6,2.6
		c0.1,0.2,0.4,0.7,0.7,1.3c0.6,1.2,1.4,3.1,2.6,4.8c0.1,0.2,0.3,0.4,0.4,0.6C120,151.2,118.9,150,118.4,149.4L118.4,149.4z
		 M139.8,152.5c-1.3,1-2.7,2-4.1,2.9c-0.8-0.2-1.7-0.3-2.7-0.3h-0.2c-0.9,0-1.7,0-2.6,0.1c-1.5,0.1-2.8,0.1-3.3,0
		c-0.8-0.3-3.4-1.8-5-4.4c-1.1-1.7-2-3.6-2.5-4.8c-0.3-0.7-0.5-1.2-0.7-1.4c-0.4-0.7-2-2.4-2.9-2.8h0c0,0-0.6,0.1-0.8-0.1
		c-0.3-0.2-0.2-0.2-0.2-0.3c0,0,0,0,0.1-0.1c0-0.1,0.2-0.3,0.3-0.3c0,0,0,0,0.1,0c0.1,0,0.3,0,0.5-0.3l0.2-0.3c0,0,0-0.1,0-0.1
		c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.3-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0
		c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.3-0.3,0.3-0.4c0.1,0,0.3-0.1,0.4-0.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0
		c0.3-0.4,1.6-2.6,2.4-3.7c0.8-1.1,4.7-5.9,5.6-6.2l0.6,1.2c0,0,0.2,0.5,0,0.9c-0.1,0.2-0.6,1-1.2,1.9c-1.1,1.7-2.5,4-3.2,5.4
		c-0.2,0.3-0.3,0.6-0.5,0.9c-0.8,1.5-1,2-0.8,3.1c0.3,1.3,1.2,4,3.5,7.2c2.2,3.2,3.8,4.5,5.3,4.5c0.9,0,7.4-0.8,12.1-1.6
		C140.1,152.2,139.9,152.3,139.8,152.5L139.8,152.5z M130.6,126.7c0,0.3-0.3,0.5-0.6,0.5l-0.2,0l0,0c0,0-0.1,0-0.1,0l-0.6,0
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0-0.7-0.4-0.7-0.8c0-0.5,0.3-0.8,0.7-0.8c0.3,0,0.5,0.2,0.6,0.4l0.8,0.1
		C130.4,126.1,130.6,126.3,130.6,126.7L130.6,126.7L130.6,126.7z M135.6,148.5c0.2,0.2,0.4,0.5,0.5,0.6c0,0,0,0,0,0
		c0,0.1-0.2,0.2-0.3,0.2c-1.1,0.3-4.2,0.6-5.9,0.5c-1.6-0.1-2.4-0.7-2.5-0.9c-0.1-0.1,0-0.3,0-0.4c0.4,0.2,1,0.6,1.9,0.6
		C130.6,149.2,134,149.1,135.6,148.5z M128,125.4c-0.3,0.2-0.5,0.3-0.7,0.4c-0.2,0.1-0.3,0.2-0.6,0.3c0,0-0.1,0-0.2,0
		c-0.6,0.2-1.1,0.3-1.1,0.5c0,0.1,0,0.2,0.2,0.3c0.5,0.3,1.7,0.9,2.8,0.9c0.5,0,0.8-0.1,1-0.2c-0.4,0.8-1.3,2.4-1.4,2.5
		c0,0.1-0.1,0.1-0.1,0.1c0,0-2.5-1.2-2.9-1.5c-0.2-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2-0.1-0.3c-0.1-0.1-0.7-0.2-1.3-0.4
		c-0.4-0.1-0.8-0.2-0.9-0.2c0,0-0.1-0.2-0.1-0.4c0-0.3-0.1-0.6-0.2-1.2c-0.3-1.1-0.9-2.1-0.9-2.1c0,0,0,0,0,0
		c0.1-0.3,2.2-0.6,3.4-0.8c0,0,0.8,0,1.2-0.5c0.3-0.3,0.4-0.4,0.5-0.5l0.6,1.7l0,0c0,0,0.7,0.8,1.7,1.4
		C128.6,125.2,128.3,125.2,128,125.4L128,125.4z M127.8,127.2c-0.3-0.1-0.9-0.2-1.4-0.5c0.1,0,0.2-0.1,0.4-0.1
		c0.3-0.1,0.5-0.2,0.7-0.3C127.5,126.6,127.5,126.9,127.8,127.2z M141.7,150.9c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		c-4.7,0.7-11.9,1.7-12.8,1.7c-1.3,0-2.8-1.3-4.9-4.3c-2.2-3.1-3.1-5.7-3.4-7.1c-0.2-0.9,0-1.3,0.7-2.8c0.1-0.3,0.3-0.6,0.5-0.9
		c0.7-1.4,2.2-3.7,3.2-5.4c0.6-1,1.1-1.7,1.2-1.9c0.3-0.6,0-1.3,0-1.3c0,0,0,0,0,0l-0.1-0.1c0.8,0.4,2.4,1.2,2.4,1.2
		c0,0,0.1,0.1,0.1,0.3c-0.1,0.2-1.6,3.9-2.2,5.3l-0.1,0.4c-0.5,1.2-1.3,3.7-1.2,5.1c0.1,1.3,0.6,2.9,1.1,4.1c0.4,1,1.2,2.5,1.5,2.7
		l0,0c0,0.2-0.1,0.5,0.1,0.7c0.2,0.3,1.2,0.9,2.9,1.1c0.3,0,0.5,0,0.9,0c1.7,0,4.1-0.3,5.1-0.5c0.1,0,0.5-0.2,0.6-0.5
		c0-0.1,0-0.2,0-0.4c-0.1-0.1-0.2-0.2-0.4-0.4c0,0,0,0,0.1,0c0.3,0.3,1.1,0.7,2.8,0.8c1.2,0,2.2,0.1,2.9,0.1h0c0.5,0,0.9,0,1,0
		c0.1,0,0.2,0.1,0.4,0.3C142.7,150.1,142.2,150.6,141.7,150.9L141.7,150.9z M153.9,137.9c-0.2,0.2-0.3,0.4-0.4,0.6
		c-0.4,0.5-0.7,1-1.2,1.5c-1.7,0.1-3.3,0.5-4.5,1.1c-1.6,0.8-2.6,2-2.7,3.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0,0.2,0
		c0,0,0.1-0.1,0.1-0.2c0.3-2.4,3-3.6,6.4-3.9c-0.7,0.9-1.3,1.7-2.1,2.5c-0.3,0.3-0.6,0.7-0.9,1c-0.5,0.6-1.1,1.2-1.7,1.8
		c-0.5,0.5-1,1-1.5,1.5c-0.6,0.6-1.2,1.2-1.8,1.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.6-0.4-0.7-0.4c-0.1,0-0.3,0-1.1,0
		c-0.7,0-1.6,0-2.9-0.1c-1.3-0.1-2-0.4-2.4-0.6c0.6-0.3,1.3-0.8,1.7-1.5c0.8-1.1,0.9-3.2-0.5-5.2c1-0.6,4.3-2.2,7.9-2.9
		C149.2,138,152,137.8,153.9,137.9C153.9,137.8,153.9,137.9,153.9,137.9L153.9,137.9z M154.5,136.9c-0.1,0.2-0.2,0.3-0.3,0.5
		c-2.1,0-5,0.2-8.6,0.8c-4.5,0.8-8.4,3.2-8.4,3.2c-0.7,0.4-1.5-0.2-1.7-0.4c-0.2-0.3-1.7-1.6-0.8-3.5c0.9-1.9,2.7-2.6,3.6-2.8
		c0.9-0.3,3.1-0.1,5.1,0c2,0.1,4.5-0.7,5.9-1.1c1.4-0.3,5.5-0.4,6.6-0.4c0.3,0,0.7,0,1,0C156.2,134.4,155.4,135.7,154.5,136.9z
		 M163.5,117.7L163.5,117.7c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.3-0.2,0.6-0.2,0.8h-62.6v46.9H100l-0.3-0.2l-56.4-32.4l0.9,1.5l54.1,31.1
		c-1.1,0-2.2-0.1-3.2-0.2c-1.7-0.1-3.3-0.3-5-0.6c-0.5-0.1-1.1-0.2-1.6-0.3c-1.2-0.2-2.4-0.5-3.6-0.7c-0.4-0.1-0.8-0.2-1.3-0.3
		c-0.9-0.2-1.8-0.5-2.7-0.8c-1.5-0.5-3-1-4.5-1.6c-0.2,0-0.3-0.1-0.5-0.2c-0.7-0.3-1.4-0.6-2.1-0.9c-0.1,0-0.1,0-0.2-0.1
		c-1.6-0.7-3.1-1.4-4.6-2.2c-1.6-0.8-3-1.7-4.5-2.7c-0.1,0-0.2-0.1-0.2-0.2c-1.4-0.9-2.7-1.8-4-2.8c-0.7-0.5-1.3-1-1.9-1.5
		c-0.7-0.6-1.5-1.2-2.2-1.9c-0.6-0.6-1.2-1.1-1.8-1.7c-0.5-0.5-1-1-1.5-1.5c-0.6-0.6-1.1-1.2-1.7-1.8c-0.3-0.3-0.6-0.7-0.9-1
		c-1.5-1.7-2.8-3.4-4.1-5.2c-0.2-0.3-0.4-0.6-0.7-1c-0.3-0.5-0.6-1-0.9-1.4l0.2,0.4l-0.2-0.4c-0.2-0.3-0.3-0.5-0.5-0.7l0,0l-0.9-1.5
		v0c-0.4-0.6-0.7-1.2-1.1-1.9c-0.3-0.5-0.5-0.9-0.7-1.4c-0.3-0.5-0.5-0.9-0.7-1.4c-0.2-0.4-0.4-0.8-0.6-1.2c0-0.1,0-0.1-0.1-0.2l0,0
		l-0.6-1.3h0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-0.7-0.6-1.4-0.9-2.2c-0.3-0.7-0.5-1.4-0.8-2.1c0-0.1-0.1-0.3-0.1-0.4
		c-0.2-0.5-0.3-0.9-0.4-1.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.5c0,0,0-0.1,0-0.1
		c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.3-0.1-0.4v0c0-0.1-0.1-0.3-0.1-0.4h127.1C163.5,117.4,163.5,117.6,163.5,117.7L163.5,117.7z
		 M164.4,113.8h-9.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9.5C164.6,113.2,164.5,113.5,164.4,113.8L164.4,113.8z
		 M165.2,109.3h-9.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9.4C165.3,108.8,165.2,109.1,165.2,109.3L165.2,109.3z
		 M165.7,104.9h-9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h9C165.7,104.4,165.7,104.6,165.7,104.9L165.7,104.9z M157.6,100.5
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h8.3v0.8L157.6,100.5L157.6,100.5z"
        />

        <g id="line">
          <path class="st0" d="M142.7,81.6l-42.3-24.3v1l40.6,23.3H142.7z" />
          <path class="st0" d="M110.9,81.6l-10.5-6v1l8.8,5H110.9z" />
          <path class="st0" d="M151.9,81.6L100.5,52v1l49.8,28.6H151.9z" />
          <path class="st0" d="M120.1,81.6l-19.6-11.3v1l17.9,10.3H120.1z" />
          <path class="st0" d="M99.6,123.9l-8.6-4.9h-1.7l10.2,5.9V123.9z" />
          <path class="st0" d="M99.6,142.1L59.3,119h-1.7l42,24.1V142.1z" />
          <path class="st0" d="M99.6,129.1L81.9,119h-1.7l19.4,11.1V129.1z" />
          <path class="st0" d="M99.6,147.4L50.1,119h-1.7l51.2,29.4V147.4z" />
          <path
            class="st0"
            d="M99.6,160.4l-60.1-34.5l0.6,1.3l59.5,34.2V160.4z"
          />
        </g>
        <path
          class="st0"
          d="M100.4,119v47.8c0,0,22.6,1.6,43.9-16.8c0,0,13.1-9.8,19.6-30.6l0.1-0.4H100.4z"
        />
        <g id="text_cover">
          <rect x="54.7" y="86.8" class="st0" width="21.1" height="26.6" />
          <rect x="77" y="86.8" class="st0" width="21.1" height="26.6" />
          <rect x="99.3" y="86.8" class="st0" width="21.1" height="26.6" />
          <rect x="121.6" y="86.8" class="st0" width="21.1" height="26.6" />
        </g>
        <path
          class="st0"
          d="M137.5,106.9c0.6-0.5,0.9-1.1,0.9-1.9v-10c0-0.8-0.3-1.4-0.9-1.9c-0.4-0.3-1.4-0.8-4.2-0.8s-3.8,0.6-4.1,0.8
		c-0.6,0.5-0.8,1.1-0.8,1.9v10c0,0.8,0.3,1.4,0.8,1.9c0.3,0.3,1.2,0.8,4.1,0.8C136.2,107.8,137.1,107.2,137.5,106.9z"
        />
        <path
          class="st0"
          d="M71.7,104.6v-9c0-0.5,0-0.9-0.2-1.3c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.9-0.5
		c-0.5-0.2-1.2-0.3-2.1-0.3h-6.3v14.6h6.3c0.9,0,1.6-0.1,2.2-0.3c0.4-0.1,0.7-0.3,0.9-0.5c0.2-0.2,0.3-0.4,0.3-0.7
		C71.6,105.5,71.7,105,71.7,104.6L71.7,104.6z"
        />
      </g>
      <g class="overflow-hidden">
        <g id="scooter" class="transform -translate-x-full">
          <path
            class="st1"
            d="M147.8,141.1c-1.6,0.8-2.6,2-2.7,3.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.2
		c0.3-2.4,3.1-3.6,6.4-3.9c-0.7,0.9-1.3,1.7-2.1,2.5c-0.3,0.3-0.6,0.7-0.9,1c-0.5,0.6-1.1,1.2-1.7,1.8c-0.5,0.5-1,1-1.5,1.5
		c-0.6,0.6-1.2,1.2-1.8,1.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.6-0.4-0.7-0.4c-0.1,0-0.3,0-1.1,0c-0.7,0-1.6,0-2.9-0.1
		c-1.3-0.1-2-0.4-2.4-0.6c0.6-0.3,1.3-0.8,1.7-1.5c0.8-1.1,0.9-3.2-0.5-5.2c1-0.6,4.3-2.2,7.9-2.9c3.4-0.6,6.2-0.8,8.2-0.8
		c0,0,0,0,0,0c-0.2,0.2-0.3,0.4-0.4,0.6c-0.4,0.5-0.7,1-1.2,1.5C150.5,140.1,149,140.5,147.8,141.1L147.8,141.1z"
          />
          <path
            class="st1"
            d="M141.2,151.3c-0.1,0.1-0.2,0.2-0.3,0.2c-4.7,0.7-11.9,1.7-12.8,1.7c-1.3,0-2.8-1.3-4.9-4.3
		c-2.2-3.1-3-5.7-3.4-7.1c-0.2-0.9,0-1.3,0.7-2.8c0.1-0.3,0.3-0.6,0.5-0.9c0.7-1.4,2.2-3.7,3.2-5.4c0.6-1,1.1-1.7,1.2-1.9
		c0.3-0.6,0-1.3,0-1.3c0,0,0,0,0,0l-0.1-0.1c0.8,0.4,2.4,1.2,2.4,1.2c0,0,0.1,0.1,0.1,0.3c-0.1,0.2-1.6,3.9-2.2,5.3l-0.1,0.4
		c-0.5,1.2-1.3,3.7-1.2,5.1c0.1,1.3,0.6,2.9,1.1,4.1c0.4,1,1.2,2.5,1.5,2.7l0,0c0,0.2-0.1,0.5,0.1,0.7c0.2,0.3,1.2,0.9,2.9,1.1
		c0.3,0,0.5,0,0.9,0c1.7,0,4.1-0.3,5.1-0.5c0.1,0,0.5-0.2,0.6-0.5c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.4-0.4c0,0,0,0,0.1,0
		c0.3,0.3,1.1,0.7,2.8,0.8c1.2,0,2.2,0.1,2.9,0.1h0c0.5,0,0.9,0,1,0c0.1,0,0.2,0.1,0.4,0.3c-0.5,0.4-1,0.9-1.5,1.3
		C141.6,151.1,141.4,151.2,141.2,151.3L141.2,151.3z"
          />
          <path
            class="st1"
            d="M113.5,154.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
		l-2.7-2.9c-0.2-0.3-0.2-0.7,0-0.9c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.5,0.2L113.5,154.2L113.5,154.2z"
          />
          <path
            class="st1"
            d="M111.7,151.4c0.7-0.4,1.5-0.5,2.2-0.4c-0.2,0.3-0.5,0.9-1,1.7L111.7,151.4L111.7,151.4z"
          />
          <path
            class="st1"
            d="M110.2,153.1l2.1,2.2c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.4-0.7,0.3-1.1l1.5-2.3
		c0.6,0.6,0.9,1.4,0.9,2.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C110,153.9,110.1,153.4,110.2,153.1L110.2,153.1z"
          />
          <path
            class="st1"
            d="M115.9,151.2c-0.3,0-0.5,0.3-0.5,0.3l-1.5,2.4l-0.7-0.7c0.5-0.9,1.2-1.9,1.3-2.1c0.2-0.3,0-0.6,0-0.7l0.4-0.6
		l0.2-0.1c0.3,0,1.3-0.1,2-0.1L115.9,151.2L115.9,151.2z"
          />
          <path
            class="st1"
            d="M115,149.2C115,149.2,114.9,149.2,115,149.2l-0.1,0c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.1,0-0.2,0.1l-0.2,0.1
		c-0.3,0.1-0.7,0.3-1,0.4c-0.4,0.2-0.7,0.3-1,0.4c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.5-0.1-0.5-0.1c0,0,0-0.1,0-0.1l2.5-2.9
		c1.5-0.1,2.7,0.2,3.2,0.5c0.5,0.3,1.1,0.7,1.6,1.1l0,0C116.5,149,115,149.1,115,149.2L115,149.2z"
          />
          <path
            class="st1"
            d="M115.9,147.6c-0.6-0.3-1.7-0.6-3-0.6c-0.6,0-1.2,0.1-1.9,0.2c-2.2,0.4-3.1,0.1-3.2,0.1c0,0,0,0,0,0
		c0-0.1,0.1-0.3,0.2-0.4c1.1-1.6,4-4.1,6.2-4.5c0.3,0,0.6-0.1,0.9-0.2c0.3,0.1,0.5,0.1,0.7,0c0.8,0.4,2.2,1.9,2.6,2.6
		c0.1,0.2,0.4,0.7,0.7,1.3c0.6,1.2,1.4,3.1,2.6,4.8c0.1,0.2,0.3,0.4,0.4,0.6c-2-0.4-3.1-1.7-3.6-2.2c-0.1-0.1-0.2-0.2-0.2-0.2
		C117.7,148.8,116.7,148,115.9,147.6L115.9,147.6z"
          />
          <path
            class="st1"
            d="M109.1,147.9c0.6,0,1.3-0.1,2-0.2c0.2,0,0.4-0.1,0.6-0.1l-2.2,2.6c-0.1,0.1-0.2,0.3-0.1,0.5c0,0,0,0.1,0.1,0.1
		h0c-0.2,0.2-0.3,0.5-0.3,0.8c0,0,0,0.1,0,0.2c-0.5,0.8-0.7,1.6-0.7,2.5c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7
		c0-1.2-0.5-2.4-1.3-3.3l1.1-1.6c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.2,0.2c0.4,0.4,1.1,1.2,2.3,1.8c0.3,0.9,0.5,1.8,0.5,2.8
		c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6C105.6,151.7,106.9,149.3,109.1,147.9z"
          />
          <path
            class="st1"
            d="M133.1,155h-0.2c-0.9,0-1.7,0-2.6,0.1c-1.5,0.1-2.8,0.1-3.3,0c-0.8-0.3-3.4-1.8-5-4.4c-1.1-1.7-2-3.6-2.5-4.8
		c-0.3-0.7-0.5-1.2-0.7-1.4c-0.4-0.7-2-2.4-2.9-2.8h0c0,0-0.6,0.1-0.8-0.1c-0.3-0.2-0.2-0.2-0.2-0.3c0,0,0,0,0.1-0.1
		c0-0.1,0.2-0.3,0.3-0.3c0,0,0,0,0.1,0c0.1,0,0.3,0,0.5-0.3l0.2-0.3c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0
		c0.1-0.1,0.2-0.3,0.3-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.3,0c0.1-0.1,0.2-0.3,0.3-0.4
		c0.1,0,0.3-0.1,0.4-0.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0c0.3-0.4,1.6-2.6,2.4-3.7c0.8-1.1,4.7-5.9,5.6-6.2
		l0.6,1.2c0,0,0.2,0.5,0,0.9c-0.1,0.2-0.6,1-1.2,1.9c-1.1,1.7-2.5,4-3.2,5.4c-0.2,0.3-0.3,0.6-0.5,0.9c-0.8,1.5-1,2-0.8,3.1
		c0.3,1.3,1.2,4,3.5,7.2c2.2,3.2,3.8,4.5,5.3,4.5c0.9,0,7.4-0.8,12.1-1.6c-0.1,0.1-0.2,0.2-0.4,0.3c-1.3,1-2.7,2-4.1,2.9
		C134.9,155.1,134.1,155,133.1,155L133.1,155z"
          />
          <path
            class="st1"
            d="M130.6,126.7c0,0.3-0.3,0.5-0.6,0.5l-0.2,0l0,0c0,0-0.1,0-0.1,0l-0.6,0c-0.1,0.1-0.3,0.2-0.4,0.2
		c-0.4,0-0.7-0.4-0.7-0.8c0-0.5,0.3-0.8,0.7-0.8c0.3,0,0.5,0.2,0.6,0.4l0.8,0.1C130.4,126.1,130.6,126.3,130.6,126.7L130.6,126.7
		L130.6,126.7z"
          />
          <path
            class="st1"
            d="M135.6,148.5c0.2,0.2,0.4,0.5,0.5,0.6c0,0,0,0,0,0c0,0.1-0.2,0.2-0.3,0.2c-1.1,0.3-4.2,0.6-5.9,0.5
		c-1.6-0.1-2.4-0.7-2.5-0.9c-0.1-0.1,0-0.3,0-0.4c0.4,0.3,1,0.6,1.9,0.6C130.6,149.2,134,149.1,135.6,148.5z"
          />
          <path
            class="st1"
            d="M128,125.4c-0.3,0.2-0.5,0.3-0.7,0.4c-0.2,0.1-0.3,0.2-0.6,0.3c0,0-0.1,0-0.2,0c-0.6,0.2-1.1,0.3-1.1,0.5
		c0,0.1,0,0.2,0.2,0.3c0.5,0.3,1.7,0.9,2.8,0.9c0.5,0,0.8-0.1,1-0.2c-0.4,0.8-1.3,2.4-1.4,2.5c0,0.1-0.1,0.1-0.1,0.1
		c0,0-2.5-1.2-2.9-1.5c-0.2-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2-0.1-0.3c-0.1-0.1-0.7-0.2-1.3-0.4c-0.4-0.1-0.8-0.2-0.9-0.2
		c0,0-0.1-0.2-0.1-0.4c0-0.2-0.1-0.6-0.3-1.2c-0.3-1.1-0.9-2.1-0.9-2.1c0,0,0,0,0,0c0.1-0.3,2.2-0.6,3.4-0.8c0,0,0.8,0,1.2-0.5
		c0.3-0.3,0.4-0.4,0.5-0.5l0.6,1.7l0,0c0,0,0.7,0.8,1.7,1.4C128.6,125.2,128.3,125.2,128,125.4L128,125.4z"
          />
          <path
            class="st1"
            d="M127.8,127.2c-0.3-0.1-0.9-0.2-1.4-0.5c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.5-0.2,0.7-0.3
		C127.5,126.6,127.5,126.9,127.8,127.2z"
          />
          <path
            class="st1"
            d="M145.7,138.2c-4.5,0.8-8.4,3.2-8.4,3.2c-0.7,0.4-1.5-0.2-1.7-0.4c-0.2-0.3-1.7-1.6-0.8-3.5
		c0.9-1.9,2.7-2.6,3.6-2.8c0.9-0.3,3.1-0.1,5.1,0c2,0.1,4.5-0.7,5.9-1.1c1.4-0.3,5.5-0.4,6.6-0.4c0.3,0,0.7,0,1,0
		c-0.8,1.3-1.5,2.5-2.4,3.7c-0.1,0.2-0.2,0.3-0.3,0.5C152.1,137.4,149.2,137.6,145.7,138.2z"
          />
        </g>
      </g>
      <circle id="outside_circle" class="st2" cx="100" cy="100" r="98.5" />
      <circle id="inside_circle" class="st3" cx="100" cy="100" r="94.7" />

      <g id="text_circle">
        <path
          class="st0"
          d="M155.1,32.8l-2.5,3c18.6,15.2,30.5,38.4,30.5,64.3c0,20.7-7.6,39.6-20.1,54.1l3,2.6
		c13.1-15.2,21.1-35.1,21.1-56.7C187,73,174.6,48.7,155.1,32.8L155.1,32.8z"
        />
        <path
          class="st0"
          d="M156.5,160.8l2.7,2.9c1-0.9,1.9-1.8,2.8-2.8l-2.8-2.7C158.3,159.1,157.5,160,156.5,160.8z"
        />
        <path
          class="st0"
          d="M159.8,157.6l2.8,2.7c0.9-1,1.8-1.9,2.7-2.9l-3-2.6C161.6,155.8,160.7,156.7,159.8,157.6z"
        />
        <path
          class="st0"
          d="M152.8,164.1l2.5,3c1.1-0.9,2.2-1.8,3.2-2.8l-2.6-2.9C154.9,162.4,153.9,163.2,152.8,164.1z"
        />
        <path
          class="st0"
          d="M17,100c0-20.8,7.7-39.8,20.3-54.3l-2.9-2.6C21.1,58.4,13.1,78.3,13.1,100c0,27,12.3,51.1,31.7,67.1l2.5-3
		C28.8,148.9,17,125.8,17,100L17,100z"
        />
        <path
          class="st0"
          d="M47.5,35.8l-2.5-3c-1.1,0.9-2.2,1.9-3.3,2.8l2.6,2.9C45.4,37.6,46.4,36.7,47.5,35.8L47.5,35.8z"
        />
        <path
          class="st0"
          d="M43.7,39.1l-2.6-2.9c-1,0.9-1.9,1.8-2.8,2.8l2.8,2.7C41.9,40.8,42.8,39.9,43.7,39.1z"
        />
        <path
          class="st0"
          d="M40.4,42.3l-2.8-2.7c-0.9,0.9-1.8,1.9-2.7,2.9l2.9,2.6C38.7,44.1,39.6,43.2,40.4,42.3z"
        />
        <path
          class="st0"
          d="M52.2,169.3c0.2-0.3,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.6-0.8l-1.4-1l-2.3,3.2l0.4,0.3l0.9-1.3l1,0.7
		c0.3,0.2,0.6,0.3,0.9,0.3C51.8,169.8,52,169.6,52.2,169.3L52.2,169.3z M51.3,169.3c-0.2,0-0.4-0.1-0.7-0.2l-0.9-0.6l0.9-1.2
		l0.8,0.6c0.3,0.2,0.4,0.4,0.4,0.5c0,0.2,0,0.4-0.2,0.6C51.7,169.2,51.5,169.3,51.3,169.3z"
        />
        <path
          class="st0"
          d="M55.9,170.6c-0.1-0.3-0.3-0.5-0.6-0.7l-1.6-1l-2.2,3.3l0.4,0.3l0.9-1.4l1.1,0.7c0.1,0.1,0.2,0.1,0.2,0.2
		c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2l0.5,0.3c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3
		c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3
		c0.2,0.1,0.4,0.1,0.7,0c0.2-0.1,0.4-0.2,0.5-0.4C55.9,171.2,55.9,170.9,55.9,170.6L55.9,170.6z M55.2,171.2
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2l-0.9-0.6l0.8-1.2l1.1,0.7
		c0.2,0.1,0.3,0.3,0.4,0.5C55.3,170.9,55.3,171,55.2,171.2L55.2,171.2z"
        />
        <path
          class="st0"
          d="M59.5,173.9c0-0.3,0.1-0.5,0-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.2-0.6-0.3-0.8-0.3
		c-0.3,0-0.5,0-0.7,0.1c-0.3,0.1-0.4,0.2-0.7,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.3-0.1,0.5,0,0.8
		c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5,0,0.8-0.1c0.2-0.1,0.4-0.2,0.7-0.4
		c0.2-0.2,0.4-0.4,0.5-0.6C59.4,174.4,59.5,174.1,59.5,173.9z M59,173.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.3-0.4,0.5
		c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.5
		c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.4-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1
		c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.2,0.2,0.3,0.2,0.5C59.1,173.4,59.1,173.6,59,173.8L59,173.8z"
        />
        <path
          class="st0"
          d="M62.3,173.9l-1.2-0.6l-1.8,3.6l1.2,0.6c0.3,0.2,0.6,0.3,0.8,0.3c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4
		c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.4-1.1,0.2-1.5C63.2,174.6,62.8,174.2,62.3,173.9L62.3,173.9z M62.8,175.7
		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.8-0.4l1.4-2.8l0.8,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.4
		C62.9,175.3,62.9,175.5,62.8,175.7L62.8,175.7z"
        />
        <path
          class="st0"
          d="M67.2,176.3l-1,2.3c-0.2,0.3-0.3,0.5-0.6,0.6c-0.3,0.1-0.5,0.1-0.8-0.1c-0.3-0.1-0.5-0.3-0.6-0.6
		c-0.1-0.3-0.1-0.5,0.1-0.9l1-2.3l-0.5-0.2l-1,2.3c-0.2,0.5-0.3,0.9-0.1,1.2c0.2,0.3,0.5,0.6,1,0.8c0.4,0.2,0.9,0.3,1.2,0.1
		c0.3-0.1,0.6-0.4,0.8-0.9l1-2.3L67.2,176.3L67.2,176.3z"
        />
        <path
          class="st0"
          d="M70.9,177.7c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.6-0.2-0.8-0.1c-0.3,0-0.5,0.1-0.7,0.2
		c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.6
		c0.2,0.2,0.4,0.3,0.7,0.4c0.5,0.2,0.9,0.2,1.3,0c0.4-0.2,0.7-0.5,0.9-0.9l-0.5-0.2c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.5-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5
		c0-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.2
		c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.4,0.1,0.7l0.5,0.2c0-0.2,0.1-0.4,0-0.6c0-0.2-0.1-0.4-0.2-0.5
		C71.1,178,71,177.9,70.9,177.7L70.9,177.7z"
        />
        <path
          class="st0"
          d="M72.2,178.7l1.3,0.4l-1.1,3.4l0.5,0.2l1.1-3.4l1.3,0.4l0.1-0.4l-3-1L72.2,178.7z"
        />
        <path
          class="st0"
          d="M79.2,180.4l-1.3-0.3l-1,3.9l1.3,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.5
		c0.1-0.2,0.3-0.5,0.3-0.8c0.2-0.6,0.1-1.1-0.1-1.5C80.2,180.8,79.8,180.5,79.2,180.4L79.2,180.4z M80,182.6
		c-0.1,0.3-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.2,0l-0.9-0.2l0.8-3l0.9,0.2c0.3,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5
		C80.1,182.2,80.1,182.4,80,182.6L80,182.6z"
        />
        <path
          class="st0"
          d="M81.1,185l2.7,0.6l0.1-0.4l-2.2-0.4l0.3-1.4l2,0.4l0.1-0.4l-2-0.4l0.3-1.2l2.2,0.4l0.1-0.4l-2.7-0.6L81.1,185z
		"
        />
        <path
          class="st0"
          d="M85.9,182.6c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.6,0.3
		c0.2,0.2,0.2,0.4,0.2,0.7l0.5,0.1c0-0.2,0-0.4,0-0.6c-0.1-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0,0.3,0,0.5
		c0,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.3-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0-0.4l-0.5-0.1
		c0,0.2,0,0.4,0,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4,0,0.6,0
		c0.2,0,0.4-0.1,0.5-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1-0.1-0.1-0.2-0.1-0.3C85.8,182.7,85.9,182.6,85.9,182.6L85.9,182.6z"
        />
        <path class="st0" d="M88.9,186.3l0.5,0.1l0.5-4l-0.5-0.1L88.9,186.3z" />
        <path
          class="st0"
          d="M91.7,183.5c0.1-0.2,0.3-0.3,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.3,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4l0.5,0c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0-0.9,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8
		c0,0.3,0,0.5,0.1,0.8c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.8,0.2c0.2,0,0.5,0,0.7-0.1
		c0.3-0.1,0.4-0.2,0.6-0.4l0.1,0.5l0.3,0l0.2-2.1l-1.7-0.2l0,0.4l1.3,0.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.4,0.1-0.6C91.5,183.9,91.6,183.7,91.7,183.5L91.7,183.5z"
        />
        <path
          class="st0"
          d="M98.2,186.3L98.2,186.3l-2-3.3l-0.6,0l-0.1,4l0.5,0l0.1-3.2l2,3.3l0.6,0l0.1-4l-0.5,0L98.2,186.3z"
        />
        <path
          class="st0"
          d="M106.5,185.8c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4l-0.6,0c0,0.3,0,0.6-0.1,0.9
		l-1.1-1.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.4-0.1-0.5
		c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.3,0.2-0.4,0.3
		c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1-0.1,0.3,0,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3
		c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.6
		c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.6,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.6-0.1,0.9-0.3
		c0.3-0.2,0.5-0.4,0.7-0.6l0.7,0.7l0.9,0l-1.2-1.2C106.4,186,106.5,185.9,106.5,185.8L106.5,185.8z M103.8,183
		c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.4,0,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2,0,0.3
		c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.1-0.2-0.1-0.2C103.8,183.2,103.8,183.1,103.8,183L103.8,183z M105.1,186.8c-0.2,0.1-0.4,0.2-0.7,0.2
		c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.4
		c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.4-0.3l1.4,1.5C105.5,186.5,105.3,186.6,105.1,186.8L105.1,186.8
		z"
        />
        <path
          class="st0"
          d="M113.5,182.4c-0.4-0.3-0.9-0.3-1.5-0.3l-1.4,0.2l0.6,4l1.3-0.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.1,0.4-0.3,0.5-0.5
		c0.1-0.2,0.2-0.4,0.3-0.7c0-0.3,0-0.6,0-0.9C114.1,183.1,113.8,182.7,113.5,182.4L113.5,182.4z M113.5,185
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0l-0.9,0.1l-0.4-3.1l0.9-0.1
		c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.2,0.6c0,0.3,0,0.4,0,0.6
		C113.6,184.8,113.6,184.9,113.5,185L113.5,185z"
        />
        <path
          class="st0"
          d="M116,185.1l-0.3-1.4l2-0.4l-0.1-0.4l-2,0.4l-0.3-1.3l2.2-0.4l-0.1-0.4l-2.7,0.5l0.8,3.9l2.7-0.5l-0.1-0.4
		L116,185.1z"
        />
        <path
          class="st0"
          d="M120.5,184.1L120.5,184.1l-1.9-3.1l-0.6,0.1l2.3,3.6l0.6-0.1l0.4-4.2l-0.5,0.1L120.5,184.1z"
        />
        <path
          class="st0"
          d="M123.4,183.4l-0.4-1.3l2-0.6l-0.1-0.4l-2,0.6l-0.4-1.2l2.1-0.6l-0.1-0.4l-2.7,0.8l1.1,3.8l2.7-0.8l-0.1-0.4
		L123.4,183.4z"
        />
        <path
          class="st0"
          d="M127.2,182.3l-1.1-3.4l-0.5,0.2l1.3,3.8l2.5-0.8l-0.2-0.4L127.2,182.3z"
        />
        <path
          class="st0"
          d="M132.4,177.8c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0-0.8,0.1
		c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.5,0.4,0.7
		c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.5,0,0.8-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.7
		c0.1-0.2,0.1-0.5,0.1-0.7c0-0.3-0.1-0.5-0.2-0.8C132.7,178.3,132.5,178,132.4,177.8z M132.4,179.9c0,0.2-0.1,0.4-0.3,0.5
		c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.3-0.3-0.5
		c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.1,0.6-0.1
		c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.3,0.4,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.2,0.6
		C132.5,179.5,132.4,179.7,132.4,179.9z"
        />
        <path
          class="st0"
          d="M135.4,175.5c-0.3-0.1-0.6-0.1-1,0.1l-1.6,0.7l1.6,3.6l0.5-0.2l-0.7-1.5l1.1-0.5c0.4-0.2,0.6-0.4,0.7-0.7
		c0.1-0.3,0.1-0.6-0.1-0.9C135.9,175.8,135.7,175.6,135.4,175.5L135.4,175.5z M135.6,176.9c-0.1,0.2-0.3,0.3-0.5,0.4l-0.9,0.4
		l-0.6-1.3l0.9-0.4c0.3-0.1,0.5-0.2,0.7-0.1c0.2,0,0.3,0.2,0.4,0.4C135.7,176.6,135.7,176.8,135.6,176.9L135.6,176.9z"
        />
        <path
          class="st0"
          d="M139.3,173.2l0.4,3.6l-2.7-2.4l-0.6,0.3l1.8,3.6l0.4-0.2l-1.5-3h0l2.6,2.4l0.4-0.2l-0.4-3.5h0l1.5,2.9l0.4-0.2
		l-1.8-3.6L139.3,173.2z"
        />
        <path
          class="st0"
          d="M143.2,175l-0.7-1.2l1.8-1.1l-0.2-0.4l-1.8,1.1l-0.6-1.1l1.9-1.1l-0.2-0.4l-2.4,1.4l2,3.5l2.4-1.4l-0.2-0.4
		L143.2,175z"
        />
        <path
          class="st0"
          d="M146.3,168.9l1.8,2.7l0,0l-3.5-1.6l-0.5,0.3l2.2,3.3l0.4-0.3l-1.8-2.7l0,0l3.5,1.6l0.5-0.3l-2.2-3.3
		L146.3,168.9z"
        />
        <path
          class="st0"
          d="M150.2,166.7l-0.3-0.4l-2.6,1.9l0.3,0.4l1.1-0.8l2.1,2.9l0.4-0.3l-2.1-2.9L150.2,166.7z"
        />
        <path
          class="st0"
          d="M50.5,33.4l-1-1.3l1-0.7c0.3-0.2,0.5-0.5,0.6-0.8c0-0.3,0-0.6-0.3-0.9c-0.2-0.3-0.5-0.5-0.8-0.5
		c-0.3,0-0.6,0-0.9,0.3l-1.4,1l2.4,3.2L50.5,33.4L50.5,33.4z M48.4,30.6l0.8-0.6c0.3-0.2,0.5-0.3,0.7-0.3c0.2,0,0.4,0.1,0.5,0.3
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.4,0.5l-0.8,0.6L48.4,30.6z"
        />
        <path
          class="st0"
          d="M53.7,31.1l-0.9-1.4l1.1-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1
		c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.2,0.1l0.5-0.3
		c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2l-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2,0-0.3,0.1h0c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.3-0.4-0.4-0.7-0.5
		c-0.3,0-0.6,0-0.9,0.3l-1.6,1l2.2,3.3L53.7,31.1L53.7,31.1z M51.8,28.2l1.1-0.7c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0,0.3,0.2,0.4,0.3
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2l-0.9,0.6L51.8,28.2L51.8,28.2z"
        />
        <path
          class="st0"
          d="M55.5,27.6c0.1,0.3,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.5-0.1,0.8-0.3
		c0.3-0.2,0.5-0.3,0.6-0.6c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3,0-0.5,0-0.8c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.2-0.2-0.4-0.3-0.7-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.2-0.5,0.4-0.6,0.6c-0.2,0.2-0.3,0.4-0.3,0.7
		c0,0.3,0,0.5,0,0.8C55.3,27.1,55.4,27.3,55.5,27.6z M55.9,25.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.6-0.2
		c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.6
		c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.4-0.1-0.6C55.7,26,55.8,25.8,55.9,25.6z"
        />
        <path
          class="st0"
          d="M61.9,26.3c0.3-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.1-0.5,0-0.7c0-0.3-0.2-0.6-0.3-0.9
		c-0.3-0.6-0.7-0.9-1.1-1c-0.4-0.1-0.9,0-1.5,0.2l-1.2,0.6l1.8,3.6L61.9,26.3L61.9,26.3z M60.3,23.1c0.2-0.1,0.4-0.2,0.6-0.2
		c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0,0.3,0,0.5
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3s-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1l-0.8,0.4l-1.4-2.8L60.3,23.1L60.3,23.1z"
        />
        <path
          class="st0"
          d="M64.7,24.6c0.4,0.1,0.8,0,1.3-0.2c0.5-0.2,0.8-0.5,0.9-0.8c0.1-0.4,0.1-0.8-0.1-1.2L65.7,20l-0.5,0.2l1.1,2.3
		c0.1,0.3,0.2,0.6,0.1,0.9c-0.1,0.3-0.3,0.4-0.6,0.6c-0.3,0.2-0.6,0.2-0.9,0.1c-0.3-0.1-0.4-0.3-0.6-0.6l-1.1-2.3l-0.5,0.2l1.1,2.3
		C64.1,24.2,64.4,24.5,64.7,24.6L64.7,24.6z"
        />
        <path
          class="st0"
          d="M67.9,22.2c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.5,0,0.8-0.1c0.5-0.2,0.8-0.5,1-0.8
		c0.2-0.4,0.2-0.8,0.1-1.3l-0.5,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.2-0.6
		c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.3,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0.2,0.6,0.4l0.5-0.2
		c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0-0.4-0.1-0.5,0c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0.1-0.5,0.3-0.7,0.4
		c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0.1,0.5,0.2,0.8C67.7,21.7,67.8,22,67.9,22.2L67.9,22.2z"
        />
        <path
          class="st0"
          d="M72.2,18l1.1,3.4l0.5-0.2l-1.1-3.4l1.3-0.4L73.8,17l-3,1l0.1,0.4L72.2,18z"
        />
        <path
          class="st0"
          d="M78.9,19.7c0.3-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3,0-0.6-0.1-0.9
		c-0.2-0.6-0.4-1-0.8-1.3c-0.4-0.2-0.9-0.3-1.5-0.1l-1.3,0.4l1,3.9L78.9,19.7L78.9,19.7z M78.1,16.3c0.2-0.1,0.4-0.1,0.6,0
		c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3-0.1,0.5
		c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.1L78,19.5l-0.8-3L78.1,16.3
		L78.1,16.3z"
        />
        <path
          class="st0"
          d="M84.4,18.5L84.3,18l-2.2,0.5l-0.3-1.4l2-0.4l-0.1-0.4l-2,0.4l-0.3-1.2l2.2-0.5l-0.1-0.4l-2.7,0.6l0.8,3.9
		L84.4,18.5z"
        />
        <path
          class="st0"
          d="M84.9,15.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0
		c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.1-0.1-0.3-0.2-0.4l-0.5,0.1c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.6,0
		c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.2-0.5
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.3,0-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2
		c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.3,0.4,0.6l0.5-0.1c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
		c-0.1-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.1,0.2-0.1,0.3,0,0.5C84.8,15.6,84.9,15.7,84.9,15.9L84.9,15.9z"
        />
        <path class="st0" d="M89.8,17.6l-0.5-4l-0.5,0.1l0.5,4L89.8,17.6z" />
        <path
          class="st0"
          d="M91.3,16.9c0.2,0.2,0.4,0.3,0.6,0.4c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5
		l0.2,0.5l0.3,0l-0.2-2.1l-1.7,0.2l0,0.4l1.3-0.1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3
		c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0
		c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.4l0.5,0c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.4-0.4
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.5-0.3,0.7
		c0,0.3-0.1,0.5,0,0.8c0,0.3,0.1,0.5,0.2,0.7C90.9,16.6,91.1,16.8,91.3,16.9L91.3,16.9z"
        />
        <path
          class="st0"
          d="M96,17.1l-0.1-3.2l2.2,3.1l0.6,0l-0.1-4l-0.5,0l0.1,3.2h0l-2.2-3.2l-0.6,0l0.1,4L96,17.1z"
        />
        <path
          class="st0"
          d="M102.9,16.7c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.5-0.3,0.7-0.5
		l0.5,0.7l1.1,0.1l-1-1.4c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.3,0.1-0.5,0.2-0.7l-0.8,0c0,0.2-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3
		l-0.8-1.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.4-0.1-0.6
		c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.3
		c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3
		c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.1,0.4-0.1,0.6
		c0,0.3,0,0.5,0.1,0.7C102.6,16.4,102.7,16.5,102.9,16.7L102.9,16.7z M104.3,12.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.3,0.1,0.5c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
		c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3C104.1,12.3,104.2,12.2,104.3,12.1
		L104.3,12.1z M103.5,15.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1l1,1.5c0,0-0.1,0.1-0.2,0.2
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.2-0.7
		C103.3,15.3,103.4,15.2,103.5,15.1z"
        />
        <path
          class="st0"
          d="M113,17.9c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.3-0.3,0.4-0.6c0.1-0.3,0.2-0.5,0.3-0.9c0.1-0.6,0-1.1-0.3-1.5
		c-0.3-0.4-0.7-0.6-1.3-0.7l-1.3-0.2l-0.6,4l1.3,0.2C112.5,17.9,112.7,17.9,113,17.9L113,17.9z M111.8,14.2l0.9,0.1
		c0.3,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.1,0.3,0.2,0.5c0,0.2,0,0.4,0,0.7c0,0.2-0.1,0.4-0.2,0.6
		c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0l-0.9-0.1L111.8,14.2
		L111.8,14.2z"
        />
        <path
          class="st0"
          d="M117.8,18.4l-2.2-0.4l0.3-1.4l2,0.4l0.1-0.4l-2-0.4l0.3-1.3l2.2,0.4l0.1-0.4l-2.7-0.5l-0.8,3.9l2.7,0.5
		L117.8,18.4z"
        />
        <path
          class="st0"
          d="M120.1,19.4l2.3-3.5l-0.5-0.1l-2,3.1l0,0l-0.3-3.6l-0.6-0.1l0.4,4.2L120.1,19.4z"
        />
        <path
          class="st0"
          d="M124.9,20.8l0.1-0.4l-2.2-0.6l0.4-1.3l2,0.6l0.1-0.4l-2-0.6l0.4-1.2l2.1,0.6l0.1-0.4l-2.7-0.8l-1.1,3.8
		L124.9,20.8z"
        />
        <path
          class="st0"
          d="M128.3,21.9l0.2-0.4l-2-0.7l1.2-3.4l-0.5-0.2l-1.3,3.8L128.3,21.9z"
        />
        <path
          class="st0"
          d="M129.8,22.4c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.8,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.6-0.5
		c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.4-0.7-0.5
		c-0.3-0.1-0.6-0.2-0.9-0.1c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8
		c0,0.3,0,0.5,0.1,0.7C129.5,22,129.7,22.2,129.8,22.4z M130,20.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.4-0.4
		c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0,0.2,0.1,0.4,0,0.6
		c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4,0-0.6-0.1
		c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.2,0-0.4,0-0.6C129.9,20.8,130,20.6,130,20.4z"
        />
        <path
          class="st0"
          d="M133.7,24.1l0.7-1.5l1.1,0.5c0.4,0.2,0.7,0.2,1,0.1c0.3-0.1,0.5-0.3,0.7-0.7c0.2-0.3,0.2-0.7,0.1-0.9
		c-0.1-0.3-0.3-0.5-0.7-0.7l-1.6-0.7l-1.7,3.6L133.7,24.1L133.7,24.1z M135.2,20.9l0.9,0.4c0.3,0.1,0.4,0.3,0.5,0.4
		c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.4-0.4,0.4c-0.2,0.1-0.4,0-0.7-0.1l-0.9-0.4L135.2,20.9L135.2,20.9z"
        />
        <path
          class="st0"
          d="M137.2,25.8l1.6-2.9l-0.4,3.5l0.4,0.2l2.7-2.4h0l-1.6,2.9l0.4,0.2l1.9-3.5l-0.7-0.3l-2.7,2.4l0.5-3.6l-0.7-0.3
		l-1.9,3.6L137.2,25.8z"
        />
        <path
          class="st0"
          d="M143.7,29.4L144,29l-1.9-1.2l0.7-1.2l1.8,1.1l0.2-0.4l-1.8-1.1l0.6-1.1l1.9,1.1l0.2-0.4l-2.4-1.4l-2,3.4
		L143.7,29.4z"
        />
        <path
          class="st0"
          d="M144.9,30.2l1.8-2.7h0l0,3.8l0.5,0.3l2.2-3.3l-0.4-0.3l-1.8,2.7l0,0l0-3.9l-0.5-0.3l-2.2,3.3L144.9,30.2z"
        />
        <path
          class="st0"
          d="M148.8,32.9l0.4,0.3l2.1-2.9l1.1,0.8l0.3-0.4l-2.6-1.9l-0.3,0.4l1.1,0.8L148.8,32.9z"
        />
      </g>
    </svg>

    <div class="absolute top-0 left-0 w-full h-full bg-main-color z-0"></div>
  </div>
</template>

<script>
import { Loading, UnLoading } from '@/gsap/loading';
export default {
  name: 'MainLoading',
  watch: {
    loading() {
      if (this.loading) {
        Loading(this.$refs.MainContent);
      } else {
        UnLoading(this.$refs.MainContent);
      }
      //else if (this.page_image_loaded && !this.loading)
    },
    page_image_loaded() {
      if (this.page_image_loaded && !this.loading) {
        UnLoading(this.$refs.MainContent);
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.main_loading > 0;
    },
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    if (this.loading) {
      Loading(this.$refs.MainContent);
    }
  },
};
</script>

<style>
#MainLoading .st0 {
  fill: #e5e5e5;
}
#MainLoading .st1 {
  fill: #0b6079;
}
#MainLoading .st2 {
  fill: none;
  stroke: #e5e5e5;
  stroke-width: 3;
}
#MainLoading .st3 {
  fill: none;
  stroke: #e5e5e5;
}
</style>
