<template>
  <footer
    id="MainFooter"
    class="relative w-full bg-main-color"
    v-if="footer_data != null"
  >
    <div
      class="flex flex-col items-center justify-center w-full max-w-screen-xl px-10 py-24 mx-auto xl:px-0 md:flex-row"
    >
      <router-link to="/" class="block mb-20 md:mr-28 md:mb-0">
        <Logo class="w-20 text-white" />
      </router-link>
      <div class="flex flex-col">
        <ol
          class="flex flex-wrap items-center justify-between order-4 mt-10 -mx-3 leading-loose lg:justify-between md:justify-start md:mt-0 md:order-1"
        >
          <li v-for="item in menu_list" :key="item.title" class="px-3">
            <router-link :to="item.url" class="font-bold text-white">{{
              item.title
            }}</router-link>
          </li>
        </ol>
        <span
          class="w-full h-[1px] bg-white my-4 opacity-10 order-2 md:block hidden"
        ></span>
        <div class="order-1 font-bold text-white uppercase md:order-3">
          <p>Vespa PRODUCT DESING & DEVELOPMENT</p>
          <p>Made in Taiwan</p>
        </div>

        <ol
          class="flex flex-wrap items-center order-2 mt-10 -mx-3 leading-loose lg:justify-between md:order-4 text-zinc-300 md:mt-5"
        >
          <li
            v-if="footer_data.company_fax.content != ''"
            class="flex flex-wrap items-center w-full px-3 md:w-auto"
          >
            FAX
            <p class="w-full md:ml-3 md:w-auto">
              {{ footer_data.company_fax.content }}
            </p>
          </li>
          <li class="flex flex-wrap items-center w-full px-3 md:w-auto">
            TEL
            <a
              target="_blank"
              :href="`tel://${footer_data.company_phone.content}`"
              class="w-full md:ml-3 md:w-auto"
              >{{ footer_data.company_phone.content }}</a
            >
          </li>
          <li class="flex flex-wrap items-center w-full px-3 md:w-auto">
            LINE
            <p class="w-full md:ml-3 md:w-auto">
              {{ footer_data.line.content }}
            </p>
          </li>
          <li class="flex flex-wrap items-center w-full px-3">
            ADD
            <p class="w-full md:ml-3 md:w-auto">
              {{ footer_data.company_address.content }}
            </p>
          </li>
        </ol>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/svg/Logo.vue";
export default {
  name: "MainFooter",
  components: {
    Logo,
  },
  data() {
    return {
      menu_list: [
        {
          title: "CUSTOMIZE",
          url: "/customize",
        },
        {
          title: "PRODUCTS",
          url: "/product_list",
        },
        {
          title: "ABOUT US",
          url: "/about",
        },
        {
          title: "NEWS",
          url: "/news_list",
        },
        {
          title: "VIDEO",
          url: "/video_list",
        },
        {
          title: "CONTACT US",
          url: "/contact",
        },
      ],
    };
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
  },
  watch: {},
  created() {
    this.footer_data == null ? this.$store.dispatch("getFooterData") : "";
  },
};
</script>
