<template>
  <section
    ref="MainContent"
    id="HomeCover"
    class="relative w-full md:h-auto h-screen md:pb-[56.25%]"
  >
    <img
      alt="DEGO 偉士牌改裝精品"
      src="/img/home/cover_title.svg"
      class="absolute md:top-0 top-24 md:bottom-0 md:left-24 left-10 md:h-full h-4/5 w-auto z-[11] md:opacity-80 opacity-40"
    />
    <div class="absolute top-0 left-0 z-10 w-full h-full dot_cover"></div>

    <!-- <video
      loop
      autoplay
      muted
      alt="DEGO 偉士牌鍛造輪框"
      :src="cover_data.video"
      class="absolute top-0 left-0 z-0 object-cover w-full h-full md:h-auto"
    ></video> -->
    <iframe
      class="absolute top-0 left-0 z-0 object-cover w-full h-full md:h-full"
      src="https://www.youtube.com/embed/vl4T1ncDdyM?controls=0&autoplay=1&mute=1&playlist=vl4T1ncDdyM&loop=1"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      autoplay
      muted
    ></iframe>
  </section>
</template>

<script>
import { Cover, CoverScroll } from '@/gsap/home';
export default {
  name: 'HomeCover',
  props: {
    cover_data: {
      type: Object,
      default: () => {
        return { video: '' };
      },
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        this.$nextTick(() => {
          // this.SetGsap()
        });
      }
    },
  },
  methods: {
    SetGsap() {
      Cover(this.$refs.MainContent);
      CoverScroll(this.$refs.MainContent);
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    this.SetGsap();
    // this.page_image_loaded ? this.SetGsap() : ''
  },
};
</script>

<style scoped>
#HomeCover .dot_cover {
  background-image: url(@/assets/img/home/dot.webp);
  background-repeat: repeat;
}
</style>
