<template>
  <div
    ref="MainEl"
    class="mb_card whitespace-normal flex-shrink-0 relative w-full h-screen max-h-[900px] mr-[33%] pb-20 xs:pt-14 pt-8 px-10 flex flex-col items-center justify-between"
  >
    <div ref="MainContent" class="z-10 w-full text-left opacity-0 rounded-xl">
      <img
        :alt="`DEGO ${product_data.title}`"
        :src="carousel_data.title_image"
        class="w-[200px] mb-5 title"
      />
      <h4
        class="overflow-hidden text-xl font-bold uppercase lg:text-5xl md:text-4xl"
      >
        <b class="block sub_title">{{
          product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝配件'
        }}</b>
      </h4>
      <h4
        class="overflow-hidden text-xl font-bold uppercase md:mt-5 lg:text-5xl md:text-3xl"
      >
        <b class="block type_title">
          {{
            product_data.product_type == 'wheel'
              ? 'FORGED WHEEL '
              : 'VESPA ACCY'
          }}</b
        >
      </h4>
      <div class="flex flex-col items-end">
        <p class="font-bold uppercase text-main-color option_title">
          {{
            product_data.product_type == 'wheel'
              ? product_data.size + ' INCH'
              : ''
          }}
        </p>
        <div class="flex items-center">
          <button
            v-for="(item, item_index) in color_option"
            :key="item.id"
            :style="`background-color:${item.title}`"
            :class="item_index == color_option.length - 1 ? '' : ' mr-3 '"
            class="w-6 h-3 border border-white rounded-full option lg:w-8 lg:h-5"
          ></button>
        </div>
      </div>
      <div class="text-right">
        <MoreButton
          text="VIEW DETAILS"
          :url="`/product/${product_data.product_type}/${product_data.product_id}`"
          class="inline-flex px-5 mt-5 text-xs border rounded-full more_btn text-main-color border-main-color"
        />
      </div>
    </div>
    <img
      alt="DEGO 改裝精品"
      :src="carousel_data.scooter_mb_image"
      class="scooter absolute xs:bottom-28 bottom-24 left-0 z-0 ms:hidden block ms:w-3/5 xs:w-[350px] w-[240px] max-w-[9999px] opacity-0"
    />
    <img
      alt="DEGO 改裝精品"
      :src="carousel_data.scooter_pc_image"
      class="scooter absolute xs:bottom-20 bottom-14 left-0 z-0 ms:block hidden w-full transform -translate-x-1/4 max-w-[9999px] opacity-0"
    />
  </div>
</template>

<script>
import MoreButton from '@/components/ui/MoreButton.vue';
import { MbProductCard } from '@/gsap/home';
export default {
  name: 'MbCard',
  props: {
    cards: {
      type: Array,
    },
    index: {
      type: Number,
    },
    carousel_data: {
      type: Object,
    },
  },
  components: {
    MoreButton,
  },
  data() {
    return {
      card_tl: null,
    };
  },
  methods: {
    Active() {
      this.card_tl = MbProductCard(this.$refs.MainEl, this.$refs.MainContent);
    },
    Out() {
      this.card_tl != null ? this.card_tl.reverse() : '';
    },
    ChangeCard(val) {
      this.$emit('change-action', val);
    },
  },
  computed: {
    product_list() {
      return this.$store.state.product_data;
    },
    product_data() {
      let product = this.product_list.filter(
        (item) => item.product_id == this.carousel_data.product_id
      );
      return product.length > 0 ? product[0] : null;
    },
    color_option() {
      return this.product_data.options.filter(
        (item) => item.title == '輪框顏色'
      )[0].list;
    },
  },
};
</script>
