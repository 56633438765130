/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import store from '@/store/index';
import { Loading } from '@/gsap/loading';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/news_list',
    name: 'news_list',
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsListView.vue'),
  },
  {
    path: '/news/:id',
    name: 'news_page',
    component: () =>
      import(/* webpackChunkName: "news_page" */ '../views/NewsView.vue'),
  },
  {
    path: '/video_list',
    name: 'video_list',
    component: () =>
      import(/* webpackChunkName: "video_list" */ '../views/VideoListView.vue'),
  },
  {
    path: '/video/:id',
    name: 'video_page',
    component: () =>
      import(/* webpackChunkName: "video_page" */ '../views/VideoView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: () =>
      import(/* webpackChunkName: "dealers" */ '../views/DealersView.vue'),
    children: [
      {
        path: 'list',
        name: 'dealers_list',
        component: () =>
          import(
            /* webpackChunkName: "dealers_list" */ '../views/Dealers/ListView.vue'
          ),
      },
      {
        path: 'map/:area_id',
        name: 'dealers_map',
        component: () =>
          import(
            /* webpackChunkName: "dealers_map" */ '../views/Dealers/MapView.vue'
          ),
      },
    ],
  },
  {
    path: '/product_list',
    name: 'product_list',
    component: () =>
      import(
        /* webpackChunkName: "product_list" */ '../views/ProductListView.vue'
      ),
  },
  {
    path: '/product/wheel/:id',
    name: 'wheel_product_page',
    component: () =>
      import(
        /* webpackChunkName: "wheel_product_page" */ '../views/WheelProductPageView.vue'
      ),
  },
  {
    path: '/product/accy/:id',
    name: 'accy_product_page',
    component: () =>
      import(
        /* webpackChunkName: "accy_product_page" */ '../views/AccyProductPageView.vue'
      ),
  },
  {
    path: '/shop_cart',
    name: 'shop_cart_page',
    component: () =>
      import(/* webpackChunkName: "dealers" */ '../views/ShopCartView.vue'),
    children: [
      {
        path: 'list',
        name: 'shop_cart_list',
        component: () =>
          import(
            /* webpackChunkName: "shop_cart_list" */ '../views/ShopCart/ProductList.vue'
          ),
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ '../views/ShopCart/CheckOutForm.vue'
          ),
      },
    ],
  },
  {
    path: '/order_finish/:id',
    name: 'order_finish_page',
    component: () =>
      import(
        /* webpackChunkName: "order_finish_page" */ '../views/ThankYouView.vue'
      ),
  },
  {
    path: '/customize',
    name: 'customize',
    component: () =>
      import(/* webpackChunkName: "customize" */ '../views/CustomizeView.vue'),
  },
  {
    path: '/pay_error',
    name: 'pay_error',
    component: () =>
      import(/* webpackChunkName: "pay_error" */ '../views/PayError.vue'),
  },
  {
    path: '/index.php',
    redirect: '/',
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      y: 0,
      x: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.length > 0 &&
    from.matched.length > 0 &&
    to.matched[0].name != from.matched[0].name
  ) {
    store.commit('SetImageLoaded', false);
    Vue.nextTick(() => {
      // Loading(document.querySelector('#MainLoading'))
      next();
    });
  } else {
    next();
  }
});

export default router;
