<template>
  <header
    id="MainHeader"
    class="w-full fixed top-0 left-0 z-30 transition-colors duration-300"
    :class="menu_active ? 'bg-main-bg-color' : ''"
  >
    <div
      class="w-full max-w-screen-xl mx-auto flex lg:justify-center items-center relative"
    >
      <ol
        class="xl:pl-0 pl-5 flex items-center justify-center xl:text-base text-sm font-bold xs:py-5 py-3"
        ref="MainContent"
        :class="menu_active ? 'text-main-color' : main_color"
      >
        <li
          @mouseenter="HoverMenuItem(0)"
          class="lg:order-1 order-2 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-none cursor-pointer"
          ></span>
          <router-link
            to="/customize"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >CUSTOMIZE</router-link
          >
        </li>
        <li
          @mouseenter="HoverMenuItem(1)"
          class="lg:order-2 order-3 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-none cursor-pointer"
          ></span>
          <router-link
            to="/product_list"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >PRODUCTS</router-link
          >
        </li>
        <li
          @mouseenter="HoverMenuItem(2)"
          class="lg:order-3 order-4 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-none cursor-pointer"
          ></span>
          <router-link
            to="/about"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >ABOUT</router-link
          >
        </li>
        <li class="lg:order-4 order-1">
          <router-link to="/" class="xl:px-5 lg:px-3 pr-3 block">
            <Logo
              class="transition-all duration-300"
              :class="menu_active ? 'lg:w-7 w-6' : 'lg:w-10 w-7'"
            />
          </router-link>
        </li>
        <li
          @mouseenter="HoverMenuItem(3)"
          class="order-5 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-none cursor-pointer"
          ></span>
          <router-link
            to="/news_list"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >NEWS</router-link
          >
        </li>
        <li
          @mouseenter="HoverMenuItem(4)"
          class="order-6 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-none cursor-pointer"
          ></span>
          <router-link
            to="/video_list"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >VIDEO</router-link
          >
        </li>
        <li
          @mouseenter="HoverMenuItem(5)"
          class="order-7 md:block hidden overflow-hidden relative menu_item"
        >
          <span
            class="absolute top-0 left-0 right-0 bottom-0 bg-main-color transform translate-y-[105%] pointer-events-non cursor-pointer"
          ></span>
          <router-link
            to="/dealers/list"
            class="block lg:px-2 px-4 xl:w-32 lg:w-28 text-center"
            >DEALERS</router-link
          >
        </li>
      </ol>
      <ol
        class="absolute top-1/2 xl:right-0 lg:right-10 right-5 transform -translate-y-1/2 flex items-center justify-center"
        :class="menu_active ? 'text-main-color' : main_color"
      >
        <li class="px-2">
          <router-link to="/contact">
            <Mail class="w-7" />
          </router-link>
        </li>
        <li class="px-2 flex items-center">
          <button class="relative" @click="OpenShopCart">
            <span
              class="w-4 h-4 rounded-full bg-main-color block absolute z-10 -top-2 -right-2 text-xs text-white text-center"
            >
              <b class="block transform scale-[.7] font-normal">{{
                product_count
              }}</b>
            </span>
            <Cart class="w-5 relative z-0" />
          </button>
        </li>
        <li class="pl-2 md:hidden block">
          <button @click="$emit('open-action')">
            <Menu class="w-5" />
          </button>
        </li>
      </ol>
    </div>
  </header>
</template>

<script>
import Logo from './svg/Logo.vue';
import Mail from './svg/Mail.vue';
import Cart from './svg/Cart.vue';
import Menu from './svg/Menu.vue';
import { HoverMenuItem } from '@/gsap/header';
export default {
  name: 'MainHeader',
  data() {
    return {
      scrollY: 0,
      window_width: window.innerWidth,
      resize_timer: null,
    };
  },
  methods: {
    HoverMenuItem(index) {
      let item = this.$refs.MainContent.querySelectorAll('.menu_item');
      HoverMenuItem(item[index]);
    },
    OpenShopCart() {
      this.$store.commit('SetShopCartDrawer', true);
    },
  },
  computed: {
    menu_active() {
      return this.scrollY > 200 ? true : false;
    },
    main_color() {
      let pc_blue_color_list = [
        'news_list',
        'news_page',
        'video_list',
        'video_page',
        'error_page',
        'contact',
        'dealers_list',
        'dealers_map',
        'product_list',
        'wheel_product_page',
        'accy_product_page',
        'shop_cart_list',
        'checkout',
        'order_finish_page',
        'customize',
        'pay_error',
      ];
      let mb_blue_color_list = [
        'news_list',
        'news_page',
        'video_list',
        'video_page',
        'error_page',
        'contact',
        'dealers_list',
        'dealers_map',
        'product_list',
        'wheel_product_page',
        'shop_cart_list',
        'checkout',
        'order_finish_page',
        'customize',
        'pay_error',
      ];
      if (this.window_width > 768) {
        return pc_blue_color_list.indexOf(this.$route.name) != -1
          ? 'text-main-color'
          : 'text-white';
      } else {
        return mb_blue_color_list.indexOf(this.$route.name) != -1
          ? 'text-main-color'
          : 'text-white';
      }
    },
    shop_cart() {
      return this.$store.state.shop_cart;
    },
    product_count() {
      let count = 0;
      this.shop_cart.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
  },
  components: { Logo, Mail, Cart, Menu },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
    window.addEventListener('resize', () => {
      this.resize_timer != null ? clearTimeout(this.resize_timer) : '';
      this.resize_timer = setTimeout(() => {
        this.window_width = window.innerWidth;
      }, 100);
    });
  },
};
</script>
