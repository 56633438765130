<template>
  <section ref="MainContent" id="HomeVideo" class="relative w-full">
    <div class="relative flex items-stretch w-full">
      <div
        class="md:w-[30%] md:relative absolute z-[11] md:top-auto top-1/2 md:left-auto left-1/2 transform md:translate-x-0 -translate-x-1/2 md:translate-y-0 -translate-y-1/2 flex items-center justify-center"
      >
        <header class="relative z-10 text-center">
          <h2 class="text-3xl font-bold md:text-white text-main-color title">
            VIDEO
          </h2>

          <MoreButton
            text="VIEW VIDEOS"
            url="/video_list"
            class="inline-flex px-5 mt-5 duration-300 border more_btn md:text-white text-main-color md:border-white border-main-color transition-color hover:text-main-color hover:bg-white"
          />
        </header>

        <div
          class="absolute z-[1] top-0 left-0 w-full h-full bg opacity-10 md:block hidden"
        ></div>
        <div
          class="absolute top-0 left-0 z-0 hidden w-full h-full bg-main-color md:block"
        ></div>
      </div>
      <div class="relative z-10 flex-1">
        <div
          class="absolute top-0 left-0 z-10 block w-full h-full bg-white opacity-70 md:hidden"
        ></div>
        <img
          alt="DEGO 偉士牌改裝輪框"
          :src="bg_data.image"
          class="relative z-0 block w-full cover"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MoreButton from '@/components/ui/MoreButton.vue';
import { Video } from '@/gsap/home';
export default {
  name: 'HomeVideo',
  props: {
    bg_data: {
      type: Object,
    },
  },
  components: {
    MoreButton,
  },
  methods: {
    InitGsap() {
      // if (this.$store.state.page_image_loaded) {
      this.$nextTick(() => {
        Video(this.$refs.MainContent);
      });
      // }
    },
  },
  watch: {
    '$store.state.page_image_loaded'() {
      // this.InitGsap()
    },
  },
  mounted() {
    this.InitGsap();
  },
};
</script>

<style scoped>
#HomeVideo .bg {
  background-image: url('@/assets/img/home/about_bg.webp');
  background-size: cover;
  background-position: right;
}
</style>
