<template>
  <div
    ref="MainEl"
    class="pc_card whitespace-normal flex-shrink-0 relative w-full max-w-screen-lg h-screen mr-[33%] flex items-center justify-end"
  >
    <div
      ref="MainContent"
      class="relative z-0 w-[70%] bg-[#8F8F8F] text-white text-left pt-24 pb-10 rounded-xl opacity-0"
    >
      <img
        :src="carousel_data.title_image"
        :alt="`DEGO ${product_data.title}`"
        class="title absolute -top-32 md:right-0 md:left-auto left-1/2 transform md:translate-x-0 -translate-x-1/2 w-[90%] opacity-0"
      />
      <div class="relative w-2/3 ml-auto px-9" v-if="product_data != null">
        <h4
          class="overflow-hidden text-xl font-bold uppercase lg:text-4xl md:text-4xl"
        >
          <b class="block sub_title transform translate-y-[110%]">{{
            product_data.product_type == 'wheel'
              ? 'FORGED WHEEL '
              : 'VESPA ACCY'
          }}</b>
        </h4>
        <h4
          class="overflow-hidden text-xl font-bold uppercase md:mt-5 lg:text-4xl md:text-3xl"
        >
          <b class="block type_title transform translate-y-[110%]">{{
            product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝配件'
          }}</b>
        </h4>
        <div class="flex items-center mt-5">
          <p
            class="font-bold uppercase opacity-0 mr-7 lg:text-2xl md:text-xl option_title"
          >
            {{
              product_data.product_type == 'wheel'
                ? product_data.size + ' INCH'
                : ''
            }}
          </p>
          <div class="flex items-center">
            <button
              v-for="item in color_option"
              :key="item.title"
              :style="`background-color:${item.title}`"
              class="w-6 h-3 mr-3 border border-white rounded-full option lg:w-8 lg:h-5"
            ></button>
          </div>
        </div>
        <MoreButton
          :url="`/product/${product_data.product_type}/${product_data.product_id}`"
          text="VIEW DETAILS"
          class="inline-flex px-5 mt-10 transition-colors duration-300 border border-white rounded-full more_btn hover:bg-white hover:text-main-color"
        />
      </div>

      <div
        class="absolute right-0 flex items-center justify-between w-1/2 -bottom-12"
      >
        <MoreButton
          text="PREV"
          :reverse="true"
          arrow="left"
          class="inline-flex px-0 py-0 transition-colors duration-300 text-main-color hover:text-black"
          @click="ChangeCard(-1)"
        />
        <ol class="flex items-center">
          <li
            v-for="(item, item_index) in cards"
            :key="item.carousel_id"
            class="w-3 h-2 mr-1 border rounded-full border-main-color"
            :class="item_index == index ? 'bg-main-color' : ''"
          ></li>
        </ol>
        <MoreButton
          text="NEXT"
          class="inline-flex px-0 py-0 transition-colors duration-300 text-main-color hover:text-black"
          @click="ChangeCard(1)"
        />
      </div>
    </div>

    <img
      ref="Scooter"
      alt="DEGO 偉士牌改裝精品"
      :src="carousel_data.scooter_pc_image"
      class="absolute z-10 max-w-[9999px] w-[90%] -left-[36%] top-1/2 transform -translate-y-1/2"
    />
  </div>
</template>

<script>
import MoreButton from '@/components/ui/MoreButton.vue';
import { MainProductCard } from '@/gsap/home';
export default {
  name: 'PcCard',
  props: {
    cards: {
      type: Array,
    },
    index: {
      type: Number,
    },
    carousel_data: {
      type: Object,
    },
  },
  components: {
    MoreButton,
  },
  data() {
    return {
      card_tl: null,
    };
  },
  methods: {
    Active() {
      this.card_tl = MainProductCard(this.$refs.MainEl, this.$refs.MainContent);
    },
    Out() {
      this.card_tl != null ? this.card_tl.reverse() : '';
    },
    ChangeCard(val) {
      this.$emit('change-action', val);
    },
  },
  computed: {
    product_list() {
      return this.$store.state.product_data;
    },
    product_data() {
      let product = this.product_list.filter(
        (item) => item.product_id == this.carousel_data.product_id
      );
      return product.length > 0 ? product[0] : null;
    },
    color_option() {
      return this.product_data.options.filter(
        (item) => item.title == '輪框顏色'
      )[0].list;
    },
  },
};
</script>
