<script>
import RightArrow from '../svg/RightArrow.vue'
import LeftArrow from '../svg/LeftArrow.vue'
export default {
  name: 'MoreButton',
  data() {
    return {
      hover: false,
    }
  },
  props: {
    text: {
      default: 'VIEW MORE',
      type: String,
    },
    reverse: {
      default: false,
      type: Boolean,
    },
    arrow: {
      default: 'right',
      type: String,
    },
    url: {
      default: '',
      type: String,
    },
  },
  components: {
    RightArrow,
    LeftArrow,
  },
  methods: {
    EmitClick() {
      this.$emit('click')
    },
    HoverAction(val) {
      this.hover = val
    },
  },
  computed: {
    main_tag() {
      let tag = {
        tag: this.url == '' ? 'button' : 'router-link',
        properties: {
          // px-5
          class:
            'cursor-pointer  py-3 rounded-full flex items-end justify-center text-sm leading-none transition-color duration-300',
          on: {
            click: this.EmitClick,
            mouseover: () => {
              this.HoverAction(true)
            },
            mouseout: () => {
              this.HoverAction(false)
            },
          },
        },
      }

      this.reverse ? (tag.properties.class += ' flex-row-reverse') : ''
      if (tag.tag == 'router-link') {
        tag.properties.props = {
          to: this.url,
        }
      }

      return tag
    },
    arrow_component() {
      let tag = {
        tag: this.arrow == 'left' ? 'LeftArrow' : 'RightArrow',
        properties: {
          class: 'mb-[3px] w-7 transform transition-transform duration-300',
        },
      }
      this.hover
        ? this.reverse
          ? (tag.properties.class += ' -translate-x-1')
          : (tag.properties.class += ' translate-x-1')
        : ''
      tag.properties.class += this.reverse ? ' mr-3' : ' ml-3'
      return tag
    },
  },
  render(createElement) {
    return createElement(this.main_tag.tag, this.main_tag.properties, [
      this.text,
      createElement(this.arrow_component.tag, this.arrow_component.properties),
    ])
  },
}
</script>
