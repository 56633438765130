<template>
  <div
    id="app"
    class="flex flex-col w-full min-h-screen overflow-hidden bg-main-bg-color"
  >
    <MainDialog />
    <MainLoading />
    <MainHeader
      :class="drawer_active ? 'blur-sm' : ''"
      @open-action="OpenMenu"
    />
    <MainMenu ref="MainMenu" />
    <ShopCartDialog />
    <ShopCartDrawer />
    <router-view
      v-if="seo_meta != null"
      class="flex-1 transition-all duration-300"
      :class="drawer_active ? 'blur-lg' : ''"
    />
    <Marquee :class="drawer_active ? 'blur-sm' : ''" />
    <ContactFooter :class="drawer_active ? 'blur-sm' : ''" />
    <MainFooter :class="drawer_active ? 'blur-sm' : ''" />
  </div>
</template>

<script>
import ContactFooter from './components/ContactFooter.vue';
import MainFooter from './components/MainFooter.vue';
import MainHeader from './components/MainHeader.vue';
import MainMenu from '@/components/MainMenu.vue';
import Marquee from './components/Marquee.vue';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MainLoading from './components/MainLoading.vue';
import MainDialog from './components/MainDialog.vue';
import ShopCartDrawer from './components/ShopCartDrawer.vue';
import ShopCartDialog from '@/components/ShopCartDialog';
import { GetShopCart } from '@/common/shop_cart.js';
export default {
  name: 'App',
  components: {
    ContactFooter,
    MainFooter,
    MainHeader,
    MainMenu,
    Marquee,
    MainLoading,
    MainDialog,
    ShopCartDrawer,
    ShopCartDialog,
  },
  methods: {
    OpenMenu() {
      this.$refs.MainMenu.Active();
    },
    '$store.state.page_image_loaded'() {
      ScrollTrigger.refresh();
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.matched.length > 0 &&
        from.matched.length > 0 &&
        to.matched[0].name != from.matched[0].name
      ) {
        this.$store.commit('SetImageLoaded', false);
      }
    },
    drawer_active() {
      if (this.drawer_active) {
        document.querySelector('body').style.overflowY = 'hidden';
      } else {
        document.querySelector('body').style.overflowY = 'auto';
      }
    },
    product_data() {
      this.product_data != null ? GetShopCart() : '';
    },
  },
  computed: {
    drawer_active() {
      return this.$store.state.shop_cart_drawer;
    },
    product_data() {
      return this.$store.state.product_data;
    },
    seo_meta() {
      return this.$store.state.seo_meta;
    },
  },
  created() {
    this.product_data != null
      ? GetShopCart()
      : this.$store.dispatch('getProductData');
    this.seo_meta != null ? '' : this.$store.dispatch('getSeoMeta');
  },
};
</script>

<style src="@/assets/output.css"></style>
