<template>
  <section
    id="HomeMainProduct"
    class="relative w-full overflow-hidden"
    v-if="main_product_data != null"
  >
    <div class="relative w-full max-w-screen-xl mx-auto">
      <div class="items-stretch hidden pc_carousel whitespace-nowrap md:flex">
        <PCCard
          ref="pc_card"
          :cards="main_product_data"
          :index="item_index"
          :carousel_data="item"
          @change-action="ChangeCard"
          v-for="(item, item_index) in main_product_data"
          :key="`pd_card_${item.mp_id}`"
        />
      </div>
      <div class="flex items-stretch mb_carousel whitespace-nowrap md:hidden">
        <MBCard
          ref="mb_card"
          @change-action="ChangeCard"
          :carousel_data="item"
          v-for="item in main_product_data"
          :key="`mb_card_${item.mp_id}`"
        />
      </div>
      <div class="absolute bottom-0 left-0 block w-full px-10 pb-5 md:hidden">
        <MBTimeline
          :active_index="active_index"
          :carousel_data="main_product_data"
        />
        <div class="flex items-center justify-between w-ful">
          <MoreButton
            text="PREV"
            :reverse="true"
            arrow="left"
            class="inline-flex px-0 py-0 text-main-color"
            @click="ChangeCard(-1)"
          />
          <MoreButton
            text="NEXT"
            class="inline-flex px-0 py-0 text-main-color"
            @click="ChangeCard(1)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MBCard from './MainProducts/mb_card.vue';
import PCCard from './MainProducts/pc_card.vue';
import MoreButton from '@/components/ui/MoreButton.vue';
import { MainProductCarousel } from '@/gsap/home';
import MBTimeline from './MainProducts/mb_timeline.vue';
export default {
  name: 'HomeMainProduct',
  components: {
    PCCard,
    MBCard,
    MBTimeline,
    MoreButton,
  },
  data() {
    return {
      active_index: 0,
      carousel_tl: null,
      resize_timer: null,
    };
  },
  methods: {
    FirstCard() {
      this.$refs.pc_card[this.active_index].Active();
      this.$refs.mb_card[this.active_index].Active();
    },
    ChangeCard(val) {
      let cards;
      let carousel;
      let time_line = document.querySelector('#HomeMainProduct .time_line');
      if (window.innerWidth >= 768) {
        this.$refs.pc_card[this.active_index].Out();
        carousel = document.querySelector('#HomeMainProduct .pc_carousel');
        cards = document.querySelectorAll('#HomeMainProduct .pc_card');
      } else {
        this.$refs.mb_card[this.active_index].Out();
        carousel = document.querySelector('#HomeMainProduct .mb_carousel');
        cards = document.querySelectorAll('#HomeMainProduct .mb_card');
      }
      if (val == -1) {
        this.active_index == 0
          ? (this.active_index = this.main_product_data.length - 1)
          : (this.active_index -= 1);
      } else {
        this.active_index == this.main_product_data.length - 1
          ? (this.active_index = 0)
          : (this.active_index += 1);
      }
      MainProductCarousel(carousel, cards, this.active_index, time_line);
      this.carousel_tl != null ? clearTimeout(this.carousel_tl) : '';
      this.carousel_tl = setTimeout(() => {
        if (window.innerWidth >= 768) {
          this.$refs.pc_card[this.active_index].Active();
        } else {
          this.$refs.mb_card[this.active_index].Active();
        }
      }, 600);
    },
    Resize() {
      let cards;
      let carousel;
      let time_line = document.querySelector('#HomeMainProduct .time_line');
      if (window.innerWidth >= 768) {
        this.$refs.pc_card[this.active_index].Out();
        carousel = document.querySelector('#HomeMainProduct .pc_carousel');
        cards = document.querySelectorAll('#HomeMainProduct .pc_card');
      } else {
        this.$refs.mb_card[this.active_index].Out();
        carousel = document.querySelector('#HomeMainProduct .mb_carousel');
        cards = document.querySelectorAll('#HomeMainProduct .mb_card');
      }
      MainProductCarousel(carousel, cards, this.active_index, time_line);
      this.FirstCard();
    },
    InitGsap() {
      // if (this.$store.state.page_image_loaded) {
      this.$nextTick(() => {
        this.FirstCard();
        window.addEventListener('resize', () => {
          if (this.$route.name == 'home') {
            this.resize_timer != null ? clearTimeout(this.resize_timer) : '';
            this.resize_timer = setTimeout(() => {
              this.Resize();
            }, 300);
          }
        });
      });
      // }
    },
  },
  computed: {
    main_product_data() {
      return this.$store.state.main_product_data;
    },
  },
  mounted() {
    this.InitGsap();
  },
};
</script>
