<template>
  <section
    id="HomeAbout"
    class="w-full md:h-auto h-screen max-h-[900px] relative md:pt-28"
  >
    <div
      class="w-full md:h-auto h-full max-w-screen-xl relative z-10 mx-auto flex flex-wrap md:items-stretch"
    >
      <Carousel
        class="md:w-1/2 w-full md:rounded-2xl"
        :carousel_data="carousel_data"
      />
      <div
        ref="MainContent"
        class="content md:w-1/2 w-4/5 md:relative absolute z-10 right-0 bottom-0 md:py-0 py-14 md:px-10 px-5 flex items-center justify-center md:rounded-none rounded-xl overflow-hidden"
      >
        <div class="relative z-10 text-main-color">
          <h4
            class="relative inline-block md:text-2xl uppercase overflow-hidden"
          >
            <span
              class="title_cover w-full h-full absolute z-10 top-0 left-0 bg-main-color transfrom scale-x-0"
            ></span>
            <b class="title relative z-0 block opacity-0">{{
              content_data.title
            }}</b>
          </h4>
          <h2 class="md:text-4xl text-2xl font-bold uppercase overflow-hidden">
            <b class="sub_title block transfrom translate-t-[110%]">{{
              content_data.sub_title
            }}</b>
          </h2>
          <div
            class="content md:mt-10 mt-5 md:text-base text-sm font-bold leading-loose opacity-0"
            v-html="content_data.content"
          ></div>
          <MoreButton
            text="VIEW ALL"
            :url="content_data.link"
            class="more_btn mt-10 px-5 inline-flex text-main-color border border-main-color hover:bg-white opacity-0"
          />
        </div>

        <div
          class="bg bg-right w-full h-full absolute z-[1] top-0 left-0 md:hidden block opacity-[0.05]"
        ></div>
        <div
          class="w-full h-full absolute z-0 top-0 left-0 md:hidden block bg-main-bg-color"
        ></div>
      </div>
    </div>

    <div
      class="bg w-full h-full absolute z-0 top-0 left-0 md:block hidden opacity-[.05]"
    ></div>
  </section>
</template>

<script>
import MoreButton from '@/components/ui/MoreButton.vue';
import Carousel from './About/Carousel.vue';
import { AboutContent } from '@/gsap/home';
export default {
  name: 'HomeAbout',
  props: {
    content_data: {
      type: Object,
      default: () => {
        return {
          title: '',
          sub_title: '',
          content: '',
          url: '',
        };
      },
    },
    carousel_data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    MoreButton,
    Carousel,
  },
  methods: {
    SetContentGsap() {
      // if (this.$store.state.page_image_loaded) {
      this.$nextTick(() => {
        AboutContent(this.$refs.MainContent);
      });
      // }
    },
  },
  watch: {
    '$store.state.page_image_loaded'() {
      // this.SetContentGsap()
    },
  },
  mounted() {
    this.SetContentGsap();
  },
};
</script>

<style scoped>
#HomeAbout .bg {
  background-image: url(@/assets/img/home/about_bg.webp);
  background-size: cover;
}
</style>
