import Vue from 'vue';
import Vuex from 'vuex';
import {
  getHomePage,
  getFooterData,
  getAboutPage,
  getNewsData,
  getVideoData,
  getDealerData,
  getProductData,
  getPayment,
  getSeoMeta,
} from '@/api/page';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_menu: false,
    page_image_loaded: false,
    shop_cart_drawer: false,
    main_loading: 0,
    shopcart_dialog: false,
    main_dialog: {
      msg: '',
      status: false,
    },
    home_page_data: null,
    footer_data: null,
    about_page_data: null,
    news_data: null,
    video_data: null,
    dealer_data: null,
    product_data: null,
    product_page_data: null,
    payment: null,
    ship_way: null,
    main_product_data: null,
    seo_meta: null,
    shop_cart: [],
  },
  getters: {},
  mutations: {
    SetShopCartDialog(state, action) {
      state.shopcart_dialog = action;
    },
    SetCompareProducts(state, data) {
      state.compare_products = data;
    },
    SetShopCartDrawer(state, action) {
      state.shop_cart_drawer = action;
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
      state.blur = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
    SetShopCartData(state, shop_cart) {
      state.shop_cart = shop_cart;
    },
  },
  actions: {
    getHomePage(state) {
      getHomePage().then((res) => {
        state.commit('SetStateData', { key: 'home_page_data', val: res.data });
        state.commit('SetStateData', {
          key: 'news_data',
          val: res.data.news_data,
        });
        state.commit('SetStateData', {
          key: 'product_data',
          val: res.data.product_data,
        });
        state.commit('SetStateData', {
          key: 'main_product_data',
          val: res.data.main_product_data,
        });
      });
    },
    getFooterData(state) {
      getFooterData().then((res) => {
        state.commit('SetStateData', { key: 'footer_data', val: res.data });
      });
    },
    getAboutPage(state) {
      getAboutPage().then((res) => {
        state.commit('SetStateData', { key: 'about_page_data', val: res.data });
      });
    },
    getNewsData(state) {
      getNewsData().then((res) => {
        state.commit('SetStateData', { key: 'news_data', val: res.data });
      });
    },
    getVideoData(state) {
      getVideoData().then((res) => {
        state.commit('SetStateData', { key: 'video_data', val: res.data });
      });
    },
    getDealerData(state) {
      getDealerData().then((res) => {
        state.commit('SetStateData', { key: 'dealer_data', val: res.data });
      });
    },
    getProductData(state) {
      getProductData().then((res) => {
        state.commit('SetStateData', { key: 'product_data', val: res[0].data });
        state.commit('SetStateData', {
          key: 'product_page_data',
          val: res[1].data,
        });
      });
    },
    getPayment(state) {
      getPayment().then((res) => {
        state.commit('SetStateData', { key: 'payment', val: res.data.payment });
        state.commit('SetStateData', {
          key: 'ship_way',
          val: res.data.ship_way,
        });
      });
    },
    getSeoMeta(state) {
      getSeoMeta().then((res) => {
        state.commit('SetStateData', { key: 'seo_meta', val: res.data });
      });
    },
  },
  modules: {},
});
