<template>
  <svg
    viewBox="0 0 23 21"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="17.0732"
      y="18.561"
      width="2.43902"
      height="2.43902"
      rx="1"
      fill="CurrentColor"
    />
    <path
      d="M0 1H3.90244L6.51707 14.0634C6.60629 14.5126 6.85064 14.9161 7.20736 15.2032C7.56408 15.4904 8.01044 15.6429 8.46829 15.6341H17.9512C18.4091 15.6429 18.8554 15.4904 19.2122 15.2032C19.5689 14.9161 19.8132 14.5126 19.9024 14.0634L21.4634 5.87805H4.87805"
      stroke="CurrentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <rect
      x="6.82922"
      y="18.561"
      width="2.43902"
      height="2.43902"
      rx="1"
      fill="CurrentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CartIcon",
};
</script>