<template>
  <section ref="MainContent" id="HomeMarquee" class="relative w-full">
    <div class="w-full overflow-hidden">
      <div class="flex items-stretch w-full whitespace-nowrap carousel">
        <div
          v-for="item in carousel_data"
          :key="item.id"
          class="flex-shrink-0 md:w-[30%] sm:w-1/2 w-full card grayscale hover:grayscale-0 transition-all duration-300"
          @mouseenter="PauseTl(true)"
          @mouseleave="PauseTl(false)"
        >
          <img alt="DEGO 偉士牌改裝精品" :src="item" class="block w-full" />
        </div>
        <div
          v-for="item in carousel_data"
          :key="item.id"
          class="flex-shrink-0 md:w-[30%] sm:w-1/2 w-full card grayscale hover:grayscale-0 transition-all duration-300"
          @mouseenter="PauseTl(true)"
          @mouseleave="PauseTl(false)"
        >
          <img alt="DEGO 偉士牌改裝精品" :src="item" class="block w-full" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Marquee } from '@/gsap/footer';
export default {
  name: 'HomeMarquee',
  data() {
    return {
      carousel_tl: null,
      carousel_data: [
        '/img/home/img_01.webp',
        '/img/home/img_02.webp',
        '/img/home/img_03.webp',
        '/img/home/img_04.webp',
        '/img/home/img_05.webp',
        '/img/home/img_06.webp',
      ],
    };
  },
  methods: {
    PauseTl(val) {
      this.carousel_tl != null
        ? val
          ? this.carousel_tl.pause()
          : this.carousel_tl.play()
        : '';
    },
  },
  watch: {
    '$store.state.page_image_loaded'() {
      if (this.$store.state.page_image_loaded) {
        this.$nextTick(() => {
          this.carousel_tl == null
            ? (this.carousel_tl = Marquee(this.$refs.MainContent))
            : '';
        });
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      this.carousel_tl == null
        ? (this.carousel_tl = Marquee(this.$refs.MainContent))
        : '';
    }
  },
};
</script>
