<template>
  <section
    ref="MainContent"
    id="ContactFooter"
    class="relative w-full"
    v-if="footer_data != null"
  >
    <div class="flex flex-col w-full md:flex-row md:items-stretch">
      <div
        class="md:flex-1 relative md:w-auto md:h-auto w-full h-[680px] bg-black"
      >
        <div
          class="absolute z-10 w-full px-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          <h3 class="mb-5 overflow-hidden text-2xl font-bold text-white">
            <b class="block sub_title">{{
              footer_data.footer_contact.sub_title
            }}</b>
          </h3>
          <MoreButton
            text="CONTACT US"
            url="/contact"
            class="inline-flex px-5 text-2xl text-white duration-300 border border-white rounded-full more_btn transition-color hover:text-main-color hover:bg-white"
          />
        </div>
        <div
          class="absolute top-0 left-0 z-0 w-full h-full bg-bottom bg-cover cover"
          :style="`background-image: url(${footer_data.footer_contact.image})`"
        ></div>
      </div>
      <div class="md:w-1/3 md:pb-[33%] w-full pb-[50%] relative">
        <div
          class="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          <h3 class="relative inline-block text-2xl font-bold text-main-color">
            <span
              class="absolute top-0 bottom-0 left-0 right-0 z-10 scale-x-0 opacity-0 title_cover bg-main-color transfrom"
            ></span>
            <b class="relative z-0 block opacity-0 title">FOLLOW US</b>
          </h3>
          <div class="flex items-center justify-center mt-8">
            <a
              target="_blank"
              :href="footer_data.facebook.content"
              class="block mx-2 social_btn"
            >
              <Facebook class="w-7 text-main-color" />
            </a>
            <a
              target="_blank"
              :href="footer_data.instagram.content"
              class="block mx-2 social_btn"
            >
              <Instagram class="w-7 text-main-color" />
            </a>
          </div>
        </div>
        <div
          class="absolute top-0 left-0 z-0 w-full h-full bg opacity-10"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import Instagram from './svg/Instagram.vue';
import Facebook from './svg/Facebook.vue';
import MoreButton from './ui/MoreButton.vue';
import { Contact } from '@/gsap/footer';
export default {
  name: 'ContactFooter',
  components: {
    Instagram,
    Facebook,
    MoreButton,
  },
  methods: {
    CheckDataLoaded() {
      if (this.footer_data != null && this.image_loaded) {
        this.Init();
      }
    },
    Init() {
      Contact(this.$refs.MainContent);
    },
  },
  watch: {
    image_loaded() {
      this.image_loaded ? this.CheckDataLoaded() : '';
    },
    footer_data() {
      this.footer_data != null ? this.CheckDataLoaded() : '';
    },
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
    image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
</script>

<style scoped>
#ContactFooter .bg {
  background-image: url('@/assets/img/home/about_bg.webp');
  background-size: cover;
  background-position: bottom left;
}
</style>
