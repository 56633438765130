<template>
  <div
    ref="MainContent"
    id="ShopCartDrawer"
    class="fixed top-0 -left-full w-full h-full bottom-0 z-[31] flex items-center justify-end overflow-hidden"
    v-if="product_data != null"
  >
    <div
      class="content md:w-[580px] w-full h-full z-10 relative flex flex-col md:py-10 py-5 md:pl-10 md:pr-20 pl-5 pr-5"
    >
      <div class="relative z-10 flex items-center justify-between">
        <MoreButton
          @click="Close"
          text="CLOSE"
          :reverse="true"
          arrow="left"
          class="px-0 mx-0"
        />
        <p class="font-bold text-main-color" v-if="shop_cart.length > 0">
          {{ product_count }} <span class="text-xs">ITEMS</span>
        </p>
      </div>

      <div
        class="relative z-10 flex-1 pr-3 mt-5 mb-5 overflow-y-auto md:mt-10"
        v-if="shop_cart.length > 0"
      >
        <ol>
          <ListItem
            v-for="(item, item_index) in shop_cart"
            :key="`product_${item_index}`"
            :product_data="GetProduct(item.product_id)"
            :shop_cart_data="item"
          />
        </ol>
      </div>

      <div
        v-if="shop_cart.length <= 0"
        class="relative z-10 flex items-center justify-center h-full px-20"
      >
        <div class="w-full text-center">
          <p class="mb-5 text-main-color">YOUR CART IS EMPTY!</p>
          <MoreButton
            @click.native="Close"
            url="/product_list/list"
            text="CONTINUE SHOPPING"
            class="w-full py-3 transition-all duration-300 border rounded-full text-main-color border-main-color hover:bg-main-color hover:text-white"
          />
        </div>
      </div>

      <div class="relative z-10" v-if="shop_cart.length > 0">
        <div class="flex items-start justify-between">
          <router-link
            to="/shop_cart/list"
            @click.native="Close"
            class="text-sm underline md:text-base"
            >VIEW CART</router-link
          >
          <div>
            <p class="text-xs">TOTAL</p>
            <h4 class="text-xl font-bold text-main-color">
              TWD ${{ total_price | money }}
            </h4>
          </div>
        </div>
        <div class="mt-5">
          <MoreButton
            text="CHECK OUT"
            url="/shop_cart/list"
            @click.native="Close"
            class="w-full px-0 py-3 mx-0 transition-colors duration-300 border rounded-full text-main-color md:py-4 border-main-color hover:bg-main-color hover:text-white"
          />
        </div>
      </div>

      <MyCart
        class="absolute z-[2] right-0 md:top-28 top-1/2 md:w-10 w-8 transform md:translate-y-0 -translate-y-1/2 md:opacity-100 opacity-20"
      />

      <div
        class="absolute top-0 left-0 w-full h-full z-[1] bg-cover bg-right-top opacity-[.03] bg"
      ></div>
      <div
        class="absolute top-0 left-0 z-0 w-full h-full bg-main-bg-color"
      ></div>
    </div>
    <div
      @click="Close"
      class="absolute top-0 left-0 z-0 w-full h-full bg-black opacity-50 dark_cover"
    ></div>
  </div>
</template>

<script>
import MoreButton from './ui/MoreButton.vue';
import MyCart from './svg/MyCart.vue';
import ListItem from '@/components/ShopCartDrawer/list_item.vue';
import { CloseDrawer, OpenDrawer } from '@/gsap/shop_cart_drawer';
import { MoneyFormat } from '@/common/filter';
import { ConvertACCYData } from '@/common/gtm_methods';
export default {
  name: 'ShopCartDrawer',
  components: { MoreButton, MyCart, ListItem },
  watch: {
    '$store.state.shop_cart_drawer'() {
      if (this.$store.state.shop_cart_drawer) {
        this.OpenShopCart();
        OpenDrawer(this.$refs.MainContent);
      } else {
        CloseDrawer();
      }
    },
  },
  methods: {
    Close() {
      this.$store.commit('SetShopCartDrawer', false);
    },
    GetProduct(id) {
      return this.product_data.filter((item) => item.product_id == id)[0];
    },
    OpenShopCart() {
      // GTM事件
      let products = [];
      this.shop_cart.forEach((item) => {
        const product = ConvertACCYData(
          this.product_data.filter(
            (product) => product.product_id == item.product_id
          )[0],
          item.options,
          item.amount
        );
        products.push(product);
      });
      window.dataLayer.push({
        event: 'view_cart',
        items: products,
        value: 0,
        currency: 'TWD',
      });
    },
  },
  computed: {
    shop_cart() {
      return this.$store.state.shop_cart;
    },
    product_data() {
      return this.$store.state.product_data;
    },
    product_count() {
      let count = 0;
      this.shop_cart.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    total_price() {
      let total_price = 0;
      this.shop_cart.forEach((item) => {
        let product = this.GetProduct(item.product_id);
        let price = 0;
        price += parseInt(product.price);
        item.options.forEach((option) => {
          price += parseInt(option.price);
        });
        total_price += price * parseInt(item.amount);
      });
      return total_price;
    },
  },
  filters: {
    money(val) {
      return MoneyFormat(val);
    },
  },
};
</script>

<style scoped>
#ShopCartDrawer .bg {
  background-image: url('@/assets/img/home/about_bg.webp');
}
</style>
