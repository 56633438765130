import { gsap } from './gsap_loader'
var menu_tl = null
export function HoverMenuItem(el) {
  let cover = el.querySelector('span')
  let tl = gsap.timeline()
  tl.fromTo(
    cover,
    {
      y: '105%',
    },
    {
      y: '0%',
      duration: 0.3,
    },
    0
  )
  tl.fromTo(
    cover,
    {
      y: '0%',
    },
    {
      y: '-105%',
      duration: 0.3,
      delay: 0.3,
    },
    0
  )
}

export function MenuActive(el) {
  let header = el.querySelectorAll('.header')
  let left_item = el.querySelector('.left')
  let bottom_item = el.querySelectorAll('.bottom')
  let menu_item = el.querySelectorAll('.menu_item')
  menu_tl == null ? '' : menu_tl.kill()
  menu_tl = gsap.timeline()

  menu_tl.set(el, {
    x: '100%',
  })
  menu_tl.fromTo(
    el,
    {
      opacity: 0,
    },
    {
      opacity: 1,
    }
  )
  menu_tl.fromTo(
    header,
    {
      y: '-100%',
      opacity: 0,
    },
    {
      y: '0%',
      opacity: 1,
    },
    0
  )
  menu_tl.fromTo(
    left_item,
    {
      x: '-30%',
      opacity: 0,
    },
    {
      x: '0%',
      opacity: 1,
      delay: 0.3,
    },
    0
  )
  menu_tl.fromTo(
    bottom_item,
    {
      y: '100%',
      opacity: 0,
    },
    {
      y: '0%',
      opacity: 1,
      stagger: 0.1,
      delay: 0.3,
    },
    0
  )
  menu_tl.fromTo(
    menu_item,
    {
      y: '10%',
      opacity: 0,
    },
    {
      y: '0%',
      opacity: 1,
      stagger: 0.1,
      delay: 0.3,
    },
    0
  )
  return menu_tl
}

export function MenuClose() {
  menu_tl.reverse()
}
