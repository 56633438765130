import { gsap } from './gsap_loader'
var drawer_tl = null

export function OpenDrawer(el) {
  let content = el.querySelector('.content')
  let dark_cover = el.querySelector('.dark_cover')
  drawer_tl != null ? drawer_tl.kill() : ''
  drawer_tl = gsap.timeline()
  drawer_tl.set(
    el,
    {
      x: '100%',
    },
    0
  )
  drawer_tl.fromTo(
    dark_cover,
    {
      opacity: 0,
    },
    {
      opacity: 0.5,
      delay: 0.1,
    },
    0
  )
  drawer_tl.fromTo(
    content,
    {
      x: '100%',
    },
    {
      x: '0%',
      delay: 0.3,
      ease: 'power4.inOut',
    },
    0
  )
}
export function CloseDrawer() {
  drawer_tl.reverse()
}
