<template>
  <div
    ref="MainDialog"
    id="MainDialog"
    class="fixed top-0 -left-full z-30 w-full h-full flex items-center justify-center"
  >
    <div
      class="main_box w-[500px] max-w-[90%] p-5 bg-main-color relative z-10 flex flex-col"
    >
      <div class="flex items-center justify-end relative z-10">
        <button
          @click="CloseDialog"
          class="transition-transform duration-200 transform hover:scale-150"
        >
          <Close class="w-4 text-white" />
        </button>
      </div>
      <div
        class="flex-1 min-h-[200px] flex items-center justify-center flex-col relative z-10"
      >
        <div class="text-white" v-html="msg"></div>
        <button
          @click="CloseDialog"
          class="text-white border border-white rounded-full px-10 py-1 mt-10 transition-colors duration-300 hover:bg-white hover:text-main-color"
        >
          CLOSE
        </button>
      </div>
      <div class="box absolute top-0 left-0 w-full h-full z-0 opacity-10"></div>
    </div>
    <div
      class="bg_cover absolute top-0 left-0 w-full h-full z-0 bg-white opacity-50"
    ></div>
  </div>
</template>

<script>
import Close from './svg/Close.vue';
import { OpenShareDialog, CloseShareDialog } from '@/gsap/dialog';
export default {
  name: 'MainDialog',
  components: {
    Close,
  },
  methods: {
    CloseDialog() {
      this.$store.commit('SetMainDialog', { action: false, msg: this.msg });
    },
  },
  computed: {
    dialog() {
      return this.$store.state.main_dialog.status;
    },
    msg() {
      return this.$store.state.main_dialog.msg;
    },
  },
  watch: {
    dialog() {
      this.dialog ? OpenShareDialog(this.$refs.MainDialog) : CloseShareDialog();
    },
  },
};
</script>
<style scoped>
#MainDialog .box {
  background-image: url('@/assets/img/home/about_bg.webp');
  background-size: cover;
}
</style>
