<template>
  <svg viewBox="0 0 28 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 7.5H4L12.1702 1.5" stroke="currentColor" stroke-width="2" />
  </svg>
</template>
<script>
export default {
  name: 'LeftArrow',
}
</script>
