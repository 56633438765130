<template>
  <div
    ref="MainDialog"
    id="ShopCartDialog"
    class="fixed w-full max-w-screen-xl left-1/2 -top-[100vh] transform z-30 pointer-events-none flex items-center justify-end"
  >
    <div
      class="content relative lg:mt-20 mt-16 xl:mr-5 lg:mr-14 md:mr-10 mr-16"
    >
      <div
        class="bg-white px-10 py-2 rounded-tl-full rounded-bl-full rounded-br-full relative overflow-hidden"
      >
        <p class="text-xs text-main-color font-bold relative z-10">
          已加入購物車!!
        </p>
        <span
          class="timeline absolute top-0 left-0 right-0 bottom-0 bg-secondary-color z-0"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { OpenShopCartDialog, CloseShopCartDialog } from '@/gsap/dialog'
export default {
  name: 'ShopCartDialog',
  computed: {
    dialog() {
      return this.$store.state.shopcart_dialog
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        OpenShopCartDialog(this.$refs.MainDialog)
        setTimeout(() => {
          CloseShopCartDialog()
          this.$store.commit('SetShopCartDialog', false)
        }, 3000)
      }
    },
  },
}
</script>
