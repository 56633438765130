import { render, staticRenderFns } from "./MyCart.vue?vue&type=template&id=1dd9b3da&"
import script from "./MyCart.vue?vue&type=script&lang=js&"
export * from "./MyCart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports