<template>
  <div class="relative z-0 overflow-hidden">
    <div
      class="absolute z-10 flex flex-col items-center text-white transform -translate-y-1/2 top-1/2 right-10 h-1/2"
    >
      <p>
        {{ (active_index + 1) | NumberText }}
      </p>
      <div class="relative flex-1 my-2">
        <span
          ref="timeline"
          class="block w-[2px] h-full bg-white absolute top-0 bottom-0 left-1/2 transform origin-top scale-y-50 -translate-x-1/2"
        ></span>
        <span class="block w-[.3px] h-full bg-white opacity-40"></span>
      </div>
      <p>
        {{ (NextIndex + 1) | NumberText }}
      </p>
    </div>
    <div
      ref="Carousel"
      class="relative z-0 flex items-stretch whitespace-nowrap"
    >
      <div
        class="flex-shrink-0 w-full"
        v-for="item in carousel_data"
        :key="item.carousel_id"
      >
        <img
          alt="DEGO 偉士牌改裝精品"
          :src="item.pc_image"
          class="hidden object-cover w-full h-full md:block"
        />
        <img
          alt="DEGO 偉士牌改裝精品"
          :src="item.mb_image"
          class="w-full md:hidden block h-[600px] object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AboutCarousel } from '@/gsap/home';
export default {
  name: 'AboutCarousel',
  props: {
    carousel_data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      active_index: 0,
      carousel_timer: null,
    };
  },
  methods: {
    ChangeImage(first_time = false) {
      this.active_index == this.carousel_data.length - 1
        ? (this.active_index = 0)
        : (this.active_index += 1);
      first_time ? (this.active_index = 0) : '';
      AboutCarousel(
        this.$refs.Carousel,
        this.active_index,
        this.$refs.timeline
      );
    },
    SetCarouselTimer() {
      if (this.$store.state.page_image_loaded) {
        this.carousel_timer != null ? clearInterval(this.carousel_timer) : '';
        this.ChangeImage(true);
        this.carousel_timer = setInterval(() => {
          this.ChangeImage();
        }, 5000);
      }
    },
  },
  computed: {
    NextIndex() {
      return this.active_index == this.carousel_data.length - 1
        ? 0
        : this.active_index + 1;
    },
  },
  filters: {
    NumberText(val) {
      return val > 10 ? val : '0' + val;
    },
  },
  watch: {
    '$store.state.page_image_loaded'() {
      this.SetCarouselTimer();
    },
  },
  mounted() {
    this.SetCarouselTimer();
  },
};
</script>
