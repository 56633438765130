<template>
  <main id="Home" class="relative w-full" v-if="page_data != null">
    <Cover :cover_data="cover_data" />
    <About
      :content_data="page_data.home_about"
      :carousel_data="page_data.carousel_data"
    />
    <MainProducts />
    <News :news_data="page_data.news_data" />
    <Video :bg_data="page_data.home_video_bg" />
  </main>
</template>

<script>
// @ is an alias to /src
import Cover from '@/components/home/Cover.vue';
import About from '@/components/home/About.vue';
import MainProducts from '@/components/home/MainProducts.vue';
import News from '@/components/home/News.vue';
import Video from '@/components/home/Video.vue';
import { LoadImage } from '@/gsap/image_loaded';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'HomeView',
  components: {
    Cover,
    About,
    MainProducts,
    News,
    Video,
  },
  data() {
    return {
      cover_data: {
        video: '/video/二代音樂（中間無字版）.mp4',
      },
      meta_data: null,
    };
  },
  methods: {
    CheckDataLoaded() {
      if (this.page_data != null) {
        LoadImage();
      }
    },
    Init() {
      this.$nextTick(() => {
        this.meta_data = GetMetaData('', '', '');
        window.prerenderReady = true;
        window.dataLayer.push({
          event: 'page_view',
          page_title: this.meta_data.title,
        });
      });
    },
  },
  computed: {
    page_data() {
      return this.$store.state.home_page_data;
    },
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  watch: {
    page_data() {
      this.page_data != null ? this.CheckDataLoaded() : '';
    },
    page_image_loaded() {
      this.page_image_loaded ? this.Init() : '';
    },
  },
  created() {
    this.page_data == null
      ? this.$store.dispatch('getHomePage')
      : this.CheckDataLoaded();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
