<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2L2 12"
      stroke="CurrentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M2 2L12 12"
      stroke="CurrentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
}
</script>
