<template>
  <div class="flex items-center justify-between">
    <p class="font-bold">{{ (active_index + 1) | NumberText }}</p>
    <div class="relative px-5 flex-1">
      <span class="h-[.5px] block bg-black"></span>
      <span
        class="time_line h-[2px] absolute top-1/2 left-5 right-5 block bg-black origin-left transform -translate-y-1/2 scale-x-50"
      ></span>
    </div>
    <p class="font-bold">{{ (next_index + 1) | NumberText }}</p>
  </div>
</template>

<script>
export default {
  name: 'MbTimeline',
  props: {
    active_index: {
      type: Number,
      default: 0,
    },
    carousel_data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    next_index() {
      return this.active_index == this.carousel_data.length - 1
        ? 0
        : this.active_index + 1
    },
  },
  filters: {
    NumberText(val) {
      return val > 10 ? val : '0' + val
    },
  },
}
</script>
