<template>
  <svg viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.27778H24" stroke="CurrentColor" stroke-width="1.5" />
    <path d="M0 7.27778H24" stroke="CurrentColor" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  name: "MenuIcon",
};
</script>