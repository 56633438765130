import { getLocalStorage, setLocalStorage } from '@/common/cookie';
import store from '@/store/index.js';

export function GetShopCart() {
  let shop_cart = getLocalStorage('shop_cart');
  if (shop_cart) {
    shop_cart = JSON.parse(shop_cart);
    store.commit('SetShopCartData', CheckShopCart(shop_cart));
  } else {
    store.commit('SetShopCartData', []);
  }
}

export function CheckShopCart(shop_cart) {
  shop_cart.forEach((item, item_index) => {
    let product = store.state.product_data.filter(
      (product) => product.product_id == item.product_id
    );
    if (product.length > 0) {
      product = product[0];
      let option_error = false;
      item.options.forEach((product_option) => {
        let option_type = product.options.filter(
          (option) => option.pot_id == product_option.pot_id
        );
        if (option_type.length > 0) {
          option_type = option_type[0];
          let option = option_type.list.filter(
            (option) => option.po_id == product_option.po_id
          );
          option.length > 0 ? '' : (option_error = true);
        } else {
          option_error = true;
        }
      });
      option_error ? shop_cart.splice(item_index, 1) : '';
    } else {
      shop_cart.splice(item_index, 1);
    }
  });
  return shop_cart;
}

export function SetShopCart() {
  setLocalStorage('shop_cart', JSON.stringify(store.state.shop_cart));
}

export function AddShopCart(product_data) {
  let add_product_data = JSON.parse(JSON.stringify(product_data));
  let product_exist = -1;
  let shop_cart = JSON.parse(JSON.stringify(store.state.shop_cart));
  let shop_cart_products = shop_cart.filter(
    (item) => item.product_id == product_data.product_id
  );
  if (shop_cart_products.length > 0) {
    shop_cart_products.forEach((shop_cart_product) => {
      let option_exist_array = [];
      add_product_data.options.forEach((add_product_option) => {
        let option = shop_cart_product.options.filter((item) => {
          return (
            item.pot_id == add_product_option.pot_id &&
            item.po_id == add_product_option.po_id
          );
        });
        option.length > 0 ? option_exist_array.push(true) : '';
      });

      option_exist_array.length == add_product_data.options.length
        ? (product_exist = shop_cart.indexOf(shop_cart_product))
        : '';
    });
  }

  shop_cart = JSON.parse(JSON.stringify(store.state.shop_cart));
  product_exist != -1
    ? (shop_cart[product_exist].amount += 1)
    : shop_cart.push(add_product_data);
  store.commit('SetShopCartData', shop_cart);
  SetShopCart();
  store.commit('SetShopCartDialog', true);
}

export function RemoveShopCart(product_data, amount) {
  let product_exist = -1;
  let shop_cart = JSON.parse(JSON.stringify(store.state.shop_cart));
  let shop_cart_products = shop_cart.filter(
    (item) => item.product_id == product_data.product_id
  );
  if (shop_cart_products.length > 0) {
    shop_cart_products.forEach((shop_cart_product) => {
      let option_exist_array = [];
      //
      product_data.options.forEach((add_product_option) => {
        let option = shop_cart_product.options.filter((item) => {
          return (
            item.pot_id == add_product_option.pot_id &&
            item.po_id == add_product_option.po_id
          );
        });
        option.length > 0 ? option_exist_array.push(true) : '';
      });

      option_exist_array.length == product_data.options.length
        ? (product_exist = shop_cart.indexOf(shop_cart_product))
        : '';
    });
  }
  if (product_exist != -1) {
    shop_cart = JSON.parse(JSON.stringify(store.state.shop_cart));
    if (amount == -1) {
      shop_cart.splice(product_exist, 1);
    } else {
      shop_cart[product_exist].amount -= amount;
      shop_cart[product_exist].amount <= 0
        ? shop_cart.splice(product_exist, 1)
        : '';
    }

    store.commit('SetShopCartData', shop_cart);
    SetShopCart();
  }
}

// export function GetShopCart() {
//   let shop_cart = getLocalStorage('shop_cart');
//   if (shop_cart) {
//     // 1;0:1,2:31,22:105;1
//     // product_id;pot_id:po_id,...;amount&...next
//     shop_cart = shop_cart.split('&');
//     shop_cart.forEach((item, item_index) => {
//       let shop_cart_str = item.split(';');
//       let product_id = shop_cart_str[0];
//       let amount = shop_cart_str[2];
//       let options = GetOptions(product_id, shop_cart_str[1]);
//       if (options != 'error') {
//         shop_cart[item_index] = {
//           product_id: product_id,
//           amount: amount,
//           options: options,
//         };
//       } else {
//         shop_cart.splice(item_index, 1);
//       }
//     });
//     return shop_cart;
//   } else {
//     return [];
//   }
// }

// function GetOptions(product_id, option_str) {
//   let product = store.product_data.filter(
//     (item) => item.product_id == product_id
//   );
//   if (product.length > 0) {
//     product = product[0];
//     let option_error = false;
//     let options = option_str[1].split(',');
//     options.forEach((option, option_index) => {
//       let option_type = product.options.filter(
//         (item) => item.pot_id == option.split(':')[0]
//       );
//       if (option_type.length > 0) {
//         option_type = option_type[0];
//         let p_option = option_type.list.filter(
//           (item) => item.po_id == option.split(':')[1]
//         );
//         if (p_option.length > 0) {
//           options[option_index] = {
//             pot_id: option.split(':')[0],
//             po_id: option.split(':')[1],
//             price: p_option[0].price,
//           };
//         } else {
//           option_error = true;
//         }
//       } else {
//         option_error = true;
//       }
//     });
//     if (option_error) {
//       return 'error';
//     } else {
//       return options;
//     }
//   } else {
//     return 'error';
//   }
// }
