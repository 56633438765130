<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 7H23C24.1 7 25 7.9 25 9V21C25 22.1 24.1 23 23 23H7C5.9 23 5 22.1 5 21V9C5 7.9 5.9 7 7 7Z"
      stroke="CurrentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25 9.00001L15 16L5 9.00001"
      stroke="CurrentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "MailIcon",
};
</script>