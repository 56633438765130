export function ConvertProductData(product_data, active_options) {
  let gtm_product = {
    item_id: product_data.product_id,
    item_name: product_data.title,
    item_brand: 'DEGO',
    price: parseInt(product_data.price),
    item_variant: GetProductOptionText(active_options),
    item_list_id:
      product_data.category.length > 0 ? product_data.category[0] : '',
    index: product_data.position,
    currency: 'TWD',
    item_category:
      product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝精品',
  };

  return gtm_product;
}

export function ConvertACCYData(product_data, active_options, amount) {
  let gtm_product = {
    item_id: product_data.product_id,
    item_name: product_data.title,
    quantity: amount,
    item_brand: 'DEGO',
    price: GetProductPrice(product_data.price, active_options),
    item_variant: GetProductOptionText(active_options),
    item_list_id:
      product_data.category.length > 0 ? product_data.category[0] : '',
    index: product_data.position,
    currency: 'TWD',
    item_category:
      product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝精品',
  };

  return gtm_product;
}

export function ConvertWheelData(product_data, active_options, amount) {
  let gtm_product = {
    item_id: product_data.product_id,
    item_name: product_data.title,
    quantity: amount,
    item_brand: 'DEGO',
    price: product_data.price,
    item_variant: GetProductOptionText(active_options),
    item_list_id:
      product_data.category.length > 0 ? product_data.category[0] : '',
    index: product_data.position,
    currency: 'TWD',
    item_category:
      product_data.product_type == 'wheel' ? '鍛造輪框' : '改裝精品',
  };

  return gtm_product;
}

export function GetProductPrice(product_price, options) {
  let price = 0;
  price += parseInt(product_price);

  options.forEach((item) => {
    price += parseInt(item.price);
  });
  return price;
}

export function GetProductOptionText(options) {
  let option_text = '';

  options.forEach((item, item_index) => {
    item_index != 0 ? (option_text += ',') : '';
    option_text += item.type_title + ':' + item.value;
  });
  return option_text;
}
