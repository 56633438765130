import { gsap, ScrollTrigger } from "./gsap_loader";
import imagesloaded from "imagesloaded";
import store from "@/store/index";

export function LoadImage() {
  //鎖住body
  // document.body.style.overflow = 'hidden';
  store.commit("SetLoading", 1);

  //初始化
  store.commit("SetImageLoaded", false);
  const images = gsap.utils.toArray("img");

  //完成事件
  const showDemo = () => {
    //復原body
    // setTimeout(() => {
    store.commit("SetLoading", -1);
    // document.body.style.overflow = 'auto';
    window.scrollTo(0, 0);
    ScrollTrigger.refresh();
    // }, 3000)

    //設定讀取完成
    store.commit("SetImageLoaded", true);
    console.log("all images have been loaded!");
  };

  imagesloaded(images).on("always", showDemo);
}
