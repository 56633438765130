<template>
  <section ref="MainContent" id="HomeNews" class="relative w-full py-40">
    <div class="w-full max-w-screen-xl px-10 mx-auto xl:px-0">
      <header
        class="flex flex-col-reverse mb-24 font-bold text-center header text-main-color"
      >
        <h2 class="overflow-hidden">
          <b class="block sub_title">最新消息</b>
        </h2>
        <h3 class="overflow-hidden text-7xl">
          <b class="block title">NEWS</b>
        </h3>
      </header>

      <div class="block mb-10 text-right md:hidden">
        <MoreButton
          text="MORE NEWS"
          url="/news_list"
          class="inline-flex px-5 duration-300 border rounded-full more_btn text-main-color border-main-color tranition-colors hover:bg-white"
        />
      </div>

      <div class="w-full md:overflow-hidden">
        <div
          ref="Carousel"
          class="flex items-stretch -mx-3 whitespace-nowrap carousel"
        >
          <article
            class="flex-shrink-0 px-3 md:w-1/4 sm:w-1/3 w-[70%] card"
            v-for="(item, item_index) in news_data"
            :key="item.news_id"
          >
            <router-link
              @mouseenter.native="hover_index = item_index"
              @mouseleave.native="hover_index = -1"
              :class="hover_index == item_index ? 'bg-white' : ''"
              class="flex flex-col h-full p-4 px-3 transition-colors duration-300 border rounded-lg border-main-color"
              :to="`/news/${item.news_id}`"
            >
              <div class="w-full rounded-md overflow-hidden pb-[100%] relative">
                <img
                  :alt="item.title"
                  :src="item.cover_image"
                  :class="hover_index == item_index ? 'scale-110' : ''"
                  class="absolute top-0 left-0 block object-cover w-full h-full transition-all duration-300 transform"
                />
              </div>
              <div class="flex flex-col flex-1 w-full">
                <h4
                  class="flex-1 w-full pt-1 mt-3 text-xl font-bold leading-none text-main-color news_title"
                >
                  {{ item.title }}
                </h4>
                <p class="mt-2 text-main-color">
                  {{ item.created_at }}
                </p>
              </div>
            </router-link>
          </article>
        </div>
      </div>

      <div
        class="flex items-center justify-between w-full mt-10 md:justify-center"
      >
        <MoreButton
          class="flex text-main-color md:hidden"
          text="PREV"
          :reverse="true"
          arrow="left"
          @click="ChangeCarousel(-1)"
        />
        <MoreButton
          text="MORE NEWS"
          url="/news_list"
          class="hidden px-8 duration-300 border rounded-full more_btn text-main-color border-main-color md:flex tranition-colors hover:bg-white"
        />
        <MoreButton
          class="flex text-main-color more_btn md:hidden"
          text="NEXT"
          @click="ChangeCarousel(1)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MoreButton from '@/components/ui/MoreButton.vue';
import { News, NewsCarousel } from '@/gsap/home';
export default {
  name: 'HomeNews',
  props: {
    news_data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    MoreButton,
  },
  data() {
    return {
      active_index: 0,
      resize_timer: null,
      hover_index: -1,
    };
  },
  methods: {
    ChangeCarousel(val) {
      val == -1
        ? this.active_index == 0
          ? (this.active_index = this.news_data.length - 1)
          : (this.active_index -= 1)
        : this.active_index == this.news_data.length - 1
        ? (this.active_index = 0)
        : (this.active_index += 1);

      NewsCarousel(
        this.$refs.Carousel,
        this.$refs.Carousel.querySelectorAll('.card'),
        this.active_index
      );
    },
    InitGsap() {
      // if (this.$store.state.page_image_loaded) {
      this.$nextTick(() => {
        News(this.$refs.MainContent);
      });
      // }
    },
  },
  watch: {
    '$store.state.page_image_loaded'() {
      // this.InitGsap()
    },
  },
  mounted() {
    this.InitGsap();
    window.addEventListener('resize', () => {
      this.resize_timer != null ? clearTimeout(this.resize_timer) : '';
      if (this.$refs.Carousel) {
        this.resize_timer = setTimeout(() => {
          if (window.innerWidth > 768) {
            this.active_index = 0;
            NewsCarousel(
              this.$refs.Carousel,
              this.$refs.Carousel.querySelectorAll('.card'),
              0
            );
          } else {
            NewsCarousel(
              this.$refs.Carousel,
              this.$refs.Carousel.querySelectorAll('.card'),
              this.active_index
            );
          }
        }, 100);
      }
    });
  },
};
</script>

<style scoped>
#HomeNews {
  background-image: url(@/assets/img/home/news_bg.webp);
  background-size: cover;
}

#HomeNews .news_title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
